import React from 'react';

const ChatEntryPointIcon = (props) => (
    <svg
        {...props}
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path fill="currentColor" d="M26.1445 31.125C26.457 31.125 26.7402 31.0176 26.9941 30.8027C27.248 30.5879 27.375 30.2852 27.375 29.8945V29.8945V25.5H29.25C30.3047 25.5 31.1934 25.1289 31.916 24.3867C32.6387 23.6445 33 22.7656 33 21.75V21.75V8.625C33 7.60937 32.6387 6.73047 31.916 5.98828C31.1934 5.24609 30.3047 4.875 29.25 4.875V4.875H6.75C5.73438 4.875 4.85547 5.24609 4.11328 5.98828C3.37109 6.73047 3 7.60937 3 8.625V8.625V21.75C3 22.7656 3.37109 23.6445 4.11328 24.3867C4.85547 25.1289 5.73438 25.5 6.75 25.5V25.5L20.8125 25.5586L25.4414 30.8906C25.5977 31.0469 25.832 31.125 26.1445 31.125Z" />
    </svg>
);

export default ChatEntryPointIcon;
