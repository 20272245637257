import classnamesBind from 'classnames/bind';
import React from 'react';

const CSS_BLOCK = 'sapcnqr-help-menu';

const EmailIcon = ({
    styles,
}) => {
    const classnames = classnamesBind.bind(styles);
    return (
        <svg
            aria-hidden
            className={classnames(
                `${CSS_BLOCK}__svg-icon`,
            )}
            width="16"
            height="12"
            viewBox="0 0 16 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path fill="currentColor" fillRule="evenodd" clipRule="evenodd" d="M15 12C15.2917 12 15.5312 11.901 15.7188 11.7031C15.9062 11.5052 16 11.2708 16 11V1C16 0.708333 15.9062 0.46875 15.7188 0.28125C15.5312 0.09375 15.2917 0 15 0H1C0.729167 0 0.494792 0.09375 0.296875 0.28125C0.0989583 0.46875 0 0.708333 0 1V11C0 11.2708 0.0989583 11.5052 0.296875 11.7031C0.494792 11.901 0.729167 12 1 12H15ZM7.99999 5.99998L1.99999 0.999985H14L7.99999 5.99998ZM5.75001 6.8125L1.50001 11H14.5L10.2813 6.8125L10.8125 6.25L15 10.5V1.5L8.00001 7L1.00001 1.5V10.5L5.15626 6.25L5.75001 6.8125Z" />
        </svg>
    );
};

export default EmailIcon;
