import React from 'react';
import classnamesBind from 'classnames/bind';

const CSS_BLOCK = 'sapcnqr-help-menu';

const ChatIcon = ({
    styles,
}) => {
    const classnames = classnamesBind.bind(styles);
    return (
        <svg
            aria-hidden
            className={classnames(
                `${CSS_BLOCK}__svg-icon`,
            )}
            width="16"
            height="16"
            viewBox="0 0 14 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path fill="currentColor" fillRule="evenodd" clipRule="evenodd" d="M11.1973 11.8527C11.0788 11.9509 10.9466 12 10.8008 12C10.6549 12 10.5456 11.9643 10.4727 11.8929L8.3125 9.45536L1.75 9.42857C1.27604 9.42857 0.865885 9.25893 0.519531 8.91964C0.173177 8.58036 0 8.17857 0 7.71429V1.71429C0 1.25 0.173177 0.848214 0.519531 0.508929C0.865885 0.169643 1.27604 0 1.75 0H12.25C12.7422 0 13.1569 0.169643 13.4941 0.508929C13.8314 0.848214 14 1.25 14 1.71429V7.71429C14 8.17857 13.8314 8.58036 13.4941 8.91964C13.1569 9.25893 12.7422 9.42857 12.25 9.42857H11.375V11.4375C11.375 11.6161 11.3158 11.7545 11.1973 11.8527ZM8.75 8.57146L10.5 10.7143V8.57146H12.25C12.5052 8.57146 12.7148 8.49111 12.8789 8.33039C13.043 8.16968 13.125 7.96432 13.125 7.71432V1.71432C13.125 1.48218 13.043 1.28128 12.8789 1.11164C12.7148 0.941999 12.5052 0.857178 12.25 0.857178H1.75C1.51302 0.857178 1.30794 0.941999 1.13477 1.11164C0.961589 1.28128 0.875 1.48218 0.875 1.71432V7.71432C0.875 7.96432 0.961589 8.16968 1.13477 8.33039C1.30794 8.49111 1.51302 8.57146 1.75 8.57146H8.75Z" />
        </svg>
    );
};

export default ChatIcon;
