import Checkbox from '@concur/nui-widgets/Checkbox';
import MessageInline from '@concur/nui-widgets/MessageInline';
import React, { useContext } from 'react';
import ApiStatus from './ApiStatus';
import HelpPortalContext from '../HelpPortal/HelpPortalContext';

const EnableNowStatus = () => {
    const { enableNowContext } = useContext(HelpPortalContext) || {};
    const {
        toggleEnableNowMenu,
        contentExists,
        setEnableNowContentExists,
        ...statusProps
    } = enableNowContext || {};

    return (
        <ApiStatus
            {...statusProps}
            apiName="EnableNow"
            buttonProps={{
                disabled: !contentExists,
                onClick: toggleEnableNowMenu,
            }}
            successContent={(
                <MessageInline
                    type="custom"
                    customIconName={contentExists
                        ? 'check'
                        : 'no'}
                    typeLabel={contentExists
                        ? 'Content exists'
                        : 'Content does not exist'}
                    title={contentExists
                        ? 'Content exists'
                        : 'Content does not exist'}
                    subtitle={(
                        <Checkbox
                            checked={contentExists}
                            inline
                            label="Content exists (override)"
                            onChange={setEnableNowContentExists}
                        />
                    )}
                />
            )}
        />
    );
};

export default EnableNowStatus;
