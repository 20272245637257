import React, { useContext, useState } from 'react';
import AssistanceStatus from './AssistanceStatus';
import HelpPortalContext from '../HelpPortal/HelpPortalContext';
import SupportModal from '../Support/SupportModal';

const CallSupportStatus = () => {
    const [showModal, setShowModal] = useState(false);

    const { callSupportContext } = useContext(HelpPortalContext) || {};
    const { supportType } = callSupportContext || {};

    return (
        <>
            <AssistanceStatus
                {...callSupportContext}
                name="Call Support"
                buttonProps={{
                    onClick: () => setShowModal(true),
                }}
            />
            <SupportModal
                onHide={() => setShowModal(false)}
                show={showModal}
                supportType={supportType}
            />
        </>
    );
};

export default CallSupportStatus;
