import fetchJson, { fetchFile } from './fetchJson';
import getCdnUrl from './getCdnUrl';
import { DEV_CDN_DOMAIN, DEV_RESPONSE_PATH } from '../constants';

const SESSION_QUERY = `query GetOnScreenHelpData($sessionId: String, $isUserAssistanceSettingsEnabled: Boolean) {
  onScreenHelp(sessionId: $sessionId, isUserAssistanceSettingsEnabled: $isUserAssistanceSettingsEnabled) {
    countryPacks {
        countryCode
        countryName
    }
    expenseConfiguration {
        groupName
        policyNames
    }
    user {
        profile {
            settings {
                expenseCountryCode
            }
            company {
              moduleProperties {
                moduleCode
              }
            }
        }
    }
    sessionData {
        AccountingSettings {
            AISConnected
        }
        activationWizardLandingPage
        activationWizardStatus
        AnalyticsId
        BreezeWSAccountSoftwareCode
        CESEntityID
        CESEntitySettings
        CESLocale
        CompanyUUID
        CUUID
        EntityActivationType
        EntityImplStatusCode
        env
        FirstName
        FullName
        LastName
        PrimaryEmail
        ProdOffering
        RoleList
        UserID
        lang
        liveAgentChatSwitch
        loginName
        supportLink
        UIPermissions
    }
    isOnScreenHelpEnabled
    isAdmin
    isStandard
    isStandardTrial
    isStandardActivation
    isProfessional
  }
}`;

function getQuery({ sessionId, isUserAssistanceSettingsEnabled }) {
    return {
        query: SESSION_QUERY,
        variables: {
            sessionId,
            isUserAssistanceSettingsEnabled,
        },
    };
}

function handleResponse(response) {
    // Fix casing of api response
    const {
        AccountingSettings: accountingSettings = {},
        AnalyticsId: analyticsId,
        BreezeWSAccountSoftwareCode: breezeWSAccountSoftwareCode,
        CESEntityID: cesEntityID,
        CESEntitySettings: cesEntitySettings,
        CESLocale: cesLocale,
        CompanyUUID: companyUUID,
        CUUID: cuuid,
        EntityActivationType: entityActivationType,
        EntityImplStatusCode: entityImplStatusCode,
        FirstName: firstName,
        FullName: fullName,
        LastName: lastName,
        PrimaryEmail: primaryEmail,
        ProdOffering: prodOffering,
        RoleList: roleList,
        UserID: userId,
        UIPermissions: uiPermissions,
        ...rest
    } = response.data.onScreenHelp.sessionData;

    const {
        expenseCountryCode,
    } = response.data.onScreenHelp.user.profile.settings;

    const {
        moduleProperties = [],
    } = response.data.onScreenHelp.user.profile?.company || {};

    const tripLinkEnabledForCompany = !!moduleProperties?.find?.(({ moduleCode }) => moduleCode === 'OpenBooking');

    const {
        countryPacks,
        expenseConfiguration = {},
        isOnScreenHelpEnabled,
        isAdmin,
        isStandard,
        isStandardTrial,
        isStandardActivation,
        isProfessional,
    } = response.data.onScreenHelp;

    const {
        groupName = '',
        policyNames = [],
    } = expenseConfiguration;

    return {
        accountingSettings,
        analyticsId,
        breezeWSAccountSoftwareCode,
        cesEntityID,
        cesEntitySettings,
        cesLocale,
        companyUUID,
        countryPacks,
        cuuid,
        entityActivationType,
        entityImplStatusCode,
        firstName,
        fullName,
        lastName,
        expenseGroupName: groupName,
        expensePolicyNames: policyNames,
        primaryEmail,
        prodOffering,
        roleList,
        userId,
        uiPermissions,
        expenseCountryCode,
        isOnScreenHelpEnabled,
        isAdmin,
        isStandard,
        isStandardTrial,
        isStandardActivation,
        isProfessional,
        tripLinkEnabledForCompany,
        ...rest,
    };
}

export function fetchOnScreenHelpDevData(devResponse) {
    let cdnUrl = getCdnUrl(__webpack_public_path__);
    if (cdnUrl?.indexOf('localhost') !== -1) {
        cdnUrl = DEV_CDN_DOMAIN;
    }
    const url = `${cdnUrl}${DEV_RESPONSE_PATH}${devResponse}.json`;

    return fetchFile(url)
        .then((response) => handleResponse(response));
}

export default function fetchOnScreenHelpData(apiContext, graphQlQueryArgs, correlationId) {
    if (!apiContext) {
        throw new Error('fetchOnScreenHelpData requires an apiContext');
    }

    const query = getQuery(graphQlQueryArgs);

    return fetchJson(apiContext, query, correlationId)
        .then((response) => handleResponse(response));
}
