const initializeChat = ({
    liveAgentConfig,
    liveAgentUserConfig,
    setIsChatAgentOnline,
}) => {
    window.liveAgentConfig = liveAgentConfig;
    window.liveAgentUserConfig = liveAgentUserConfig;

    const mockLiveAgentButton = document.createElement('div');

    const {
        liveagent,
    } = window;

    let {
        walkme,
    } = window;

    // LiveAgent API to show html elements when chat is online/offline
    liveagent.showWhenOnline(liveAgentConfig.chatId, mockLiveAgentButton);

    // Provide global JS variable for WalkMe
    liveagent.addButtonEventHandler(liveAgentConfig.chatId, (status) => {
        const isOnline = status === 'BUTTON_AVAILABLE';

        setIsChatAgentOnline(isOnline);

        // to support flags that WalkMe uses for its panel footer
        walkme = walkme || {};
        walkme.chat = {
            isOnline,
        };
    });

    // Add information for Salesforce agent
    liveagent.addCustomDetail('EntityId', liveAgentUserConfig.entityId || '');
    liveagent.addCustomDetail('LoginId', liveAgentUserConfig.loginId || '');
    liveagent.addCustomDetail('Email', liveAgentUserConfig.primaryEmail || '');
    liveagent.addCustomDetail('First Name', liveAgentUserConfig.firstName || '');
    liveagent.addCustomDetail('FullName', liveAgentUserConfig.fullName || '');
    liveagent.addCustomDetail('CTE Locale', liveAgentUserConfig.locale || '');
    liveagent.addCustomDetail('CaseSubject', `Chat with ${liveAgentUserConfig.fullName}`);
    liveagent.addCustomDetail('CaseRecordType', '01260000000UL9l');
    liveagent.addCustomDetail('CaseOrigin', 'Setup Wizard Chat');
    liveagent.addCustomDetail('CaseStatus', 'New');

    // LiveAgent set window size
    liveagent.setChatWindowHeight(800);
    liveagent.setChatWindowWidth(600);

    // LiveAgent configuration
    liveagent.findOrCreate('Case')
        .map('Subject', 'CaseSubject', false, false, true)
        .map('Status', 'CaseStatus', false, false, true)
        .map('Origin', 'CaseOrigin', false, false, true)
        .map('RecordTypeId', 'CaseRecordType', false, false, true)
        .saveToTranscript('CaseId')
        .showOnCreate();

    liveagent.findOrCreate('Contact')
        .map('Name', 'FullName', true, false, false)
        .map('Email', 'Email', true, true, false)
        .saveToTranscript('ContactId')
        .linkToEntity('Case', 'ContactId')
        .showOnCreate();

    liveagent.findOrCreate('Account')
        .map('HMC_Entity__c', 'EntityId', true, true, false)
        .linkToEntity('Case', 'AccountId')
        .saveToTranscript('AccountId');

    // Initialize LiveAgent
    liveagent.init(
        liveAgentConfig.chatUrl,
        liveAgentConfig.deploymentId,
        liveAgentConfig.orgId,
    );
};

export default initializeChat;
