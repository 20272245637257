import { canUseDOM } from 'exenv';
import PropTypes from 'prop-types';
import React, {
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    isLiveAgentChatEnabled,
    getLiveAgentConfigVariables,
    getLiveAgentUserConfigVariables,
} from '../Chat/chatUtils';
import initializeChat from '../Chat/initializeChat';
import {
    getCobrowsingScriptUrl,
    getCobrowsingEnvironment,
    isCobrowsingEnabled,
} from '../Cobrowsing/cobrowsingUtils';
import {
    SESSION_DATA_FROM_CACHE,
    SESSION_DATA_FROM_CDS,
    SESSION_DATA_NOT_AVAILABLE,
    SESSION_DATA_NOT_FETCHED,
} from '../constants';
import setupEnableNow from '../EnableNow/setupEnableNow';
import { getEnableNowConfig } from '../EnableNow/enableNowUtils';
import getCdnUrl from '../utils/getCdnUrl';
import useExternalAPI from '../utils/useExternalAPI';
import initializeWalkme from '../WalkMe/initializeWalkme';
import setupWalkme from '../WalkMe/setupWalkme';
import { getWalkMeConfig, getWalkmeVariables } from '../WalkMe/walkmeUtils';
import HelpPortalContext from './HelpPortalContext';
import setupJoule from '../Joule/setupJoule';

const HelpPortalAPIProvider = ({
    children,
    autoLoadWalkme,
    logger,
    themeName,
    userPrefs,
    jouleConfig,
}) => {
    const [cobrowsingScriptUrl, setCobrowsingScriptUrl] = useState('');

    const [isWalkmeHidden, setWalkmeHidden] = useState(false);
    const [walkmeGlobals, setWalkmeGlobals] = useState();
    const [walkmeScriptUrl, setWalkmeScriptUrl] = useState('');
    const [walkmeContentExists, setWalkmeContentExists] = useState(false);

    const [liveAgentConfig, setLiveAgentConfig] = useState();
    const [liveAgentUserConfig, setLiveAgentUserConfig] = useState();
    const [isChatAgentOnline, setIsChatAgentOnline] = useState();

    const [enableNowGlobals, setEnableNowGlobals] = useState();
    const [enableNowWebAssistant, setEnableNowWebAssistant] = useState();
    const [enableNowContentExists, setEnableNowContentExists] = useState(false);

    const [jouleWebClient, setJouleWebClient] = useState();

    const helpPortalDataContext = useContext(HelpPortalContext);
    const {
        cdsData,
        cdsStatus,
        isDebugMode,
        configOptions,
    } = helpPortalDataContext;

    const cdsDataAvailable = (
        cdsStatus === SESSION_DATA_FROM_CACHE || cdsStatus === SESSION_DATA_FROM_CDS
    );

    const {
        apiConcluded: cobrowsingConcluded,
        apiScriptLoaded: isCobrowsingLoaded,
        apiStatusMessage: cobrowsingStatusMessage,
    } = useExternalAPI({
        apiName: 'Cobrowsing',
        isAlreadyLoaded: canUseDOM && !!window.GLANCE,
        isDisabled: !configOptions?.cobrowsingEnabled,
        shouldStartLoading: cdsStatus !== SESSION_DATA_NOT_FETCHED,
        apiDataAvailable: (
            cdsDataAvailable
            && !!cobrowsingScriptUrl?.trim()
        ),
        enabledForUser: isCobrowsingEnabled(cdsData),
        apiAssetUrl: cobrowsingScriptUrl,
        apiAssetAttributes: {
            type: 'text/javascript',
            async: true,
            id: 'glance-cobrowse',
            'data-groupid': '19864',
            'data-site': getCobrowsingEnvironment(cdsData),
            charset: 'UTF-8',
        },
        isDebugMode,
    });

    const {
        senSelfEducationEnabled,
        enableNowWebAssistantUrl,
    } = getEnableNowConfig(cdsData);

    const {
        apiConcluded: enableNowConcluded,
        apiScriptLoaded: isEnableNowLoaded,
        apiStatusMessage: enableNowStatusMessage,
    } = useExternalAPI({
        apiName: 'EnableNow',
        isAlreadyLoaded: canUseDOM && !!window.Help4,
        isDisabled: !configOptions?.enableNowEnabled,
        shouldStartLoading: cdsStatus !== SESSION_DATA_NOT_FETCHED,
        apiDataAvailable: (
            cdsDataAvailable
            && !!enableNowGlobals
            && !!enableNowWebAssistantUrl?.trim?.()
        ),
        enabledForUser: (
            cdsData?.isOnScreenHelpEnabled
            && senSelfEducationEnabled
        ),
        apiAssetUrl: enableNowWebAssistantUrl,
        apiAssetAttributes: {
            type: 'text/javascript',
        },
        onApiSetup: () => {
            setupEnableNow({
                themeName,
                lang: cdsData?.lang,
                setEnableNowWebAssistant,
                setEnableNowContentExists,
                enableNowGlobals,
            });
        },
        isDebugMode,
    });

    const {
        apiConcluded: jouleConcluded,
        apiScriptLoaded: isJouleScriptLoaded,
        apiStatusMessage: jouleStatusMessage,
    } = useExternalAPI({
        apiName: 'Joule',
        isAlreadyLoaded: canUseDOM && !!window.sap?.das?.webclient?.isLoaded?.(),
        isDisabled: !!jouleConfig?.disabled
            || !!jouleConfig?.noTenantURL
            || !!configOptions?.jouleDisabled,
        shouldStartLoading: !!jouleConfig?.tenantURL,
        apiDataAvailable: !!jouleConfig?.tenantURL,
        enabledForUser: true,
        apiAssetUrl: jouleConfig?.tenantURL,
        apiAssetAttributes: {
            'data-bot-name': jouleConfig?.tenantName || '',
            'data-hidefullscreen': 'true',
            'data-expander-type': 'CUSTOM',
        },
        onApiScriptLoad: () => {
            setupJoule({
                themeName,
                setJouleWebClient,
            });
        },
        isDebugMode,
    });

    const { walkmeSelfEducationEnabled } = getWalkMeConfig(cdsData);

    const {
        apiScriptLoaded: walkmeBundleLoaded,
        apiConcluded: walkmeConcluded,
        apiStatusMessage: walkmeStatusMessage,
    } = useExternalAPI({
        apiName: 'WalkMe',
        isAlreadyLoaded: canUseDOM && !!window?.WalkMePlayerAPI,
        isDisabled: !configOptions?.walkmeEnabled,
        shouldStartLoading: cdsStatus !== SESSION_DATA_NOT_FETCHED,
        apiDataAvailable: (
            cdsDataAvailable
            && !!walkmeGlobals
            && !!walkmeScriptUrl?.trim?.()
        ),
        enabledForUser: (
            cdsData?.isOnScreenHelpEnabled
            && walkmeSelfEducationEnabled
        ),
        apiAssetUrl: walkmeScriptUrl,
        apiAssetAttributes: {
            type: 'text/javascript',
            async: true,
        },
        asyncConclude: true,
        onApiSetup: () => {
            setupWalkme({
                cdhiUrl: `${getCdnUrl(__webpack_public_path__)}/static/_third-party/walkme/CDhiddenIframe.compress.html`,
                walkmeGlobals,
            });
        },
        onApiScriptLoad: (setWalkmeReady) => {
            initializeWalkme({
                isWalkmeTourInProgress: () => window?.sessionStorage?.getItem?.('walkmeTourInProgress') === 'true',
                autoLoadWalkme,
                setWalkmeReady,
                setWalkmeHidden,
                setWalkmeContentExists,
            });
        },
        isDebugMode,
    });

    // There is a loose coupling between LiveAgent Chat and WalkMe
    // due to the fact that it was previously only accessible from the
    // WalkMe panel footer.  No actual dependency exists, but we want to
    // keep that coupling to control which users see LiveAgent Chat.
    const {
        apiScriptLoaded: isLiveAgentChatLoaded,
        apiConcluded: liveAgentChatConcluded,
        apiStatusMessage: liveAgentChatStatusMessage,
    } = useExternalAPI({
        apiName: 'LiveAgent Chat',
        isAlreadyLoaded: canUseDOM && !!window.liveagent,
        isDisabled: !configOptions?.walkmeEnabled,
        shouldStartLoading: cdsStatus !== SESSION_DATA_NOT_FETCHED,
        apiDataAvailable: (
            cdsDataAvailable
            && !!liveAgentConfig?.scriptUrl?.trim?.()
        ),
        enabledForUser: (
            cdsData?.isOnScreenHelpEnabled
            && isLiveAgentChatEnabled(cdsData)
        ),
        apiAssetUrl: liveAgentConfig?.scriptUrl,
        apiAssetAttributes: {
            type: 'text/javascript',
            async: true,
        },
        onApiScriptLoad: () => {
            initializeChat({
                liveAgentConfig,
                liveAgentUserConfig,
                setIsChatAgentOnline,
            });
        },
        isDebugMode,
    });

    useEffect(() => {
        if (!cdsData || cdsStatus === SESSION_DATA_NOT_AVAILABLE) {
            return;
        }

        setCobrowsingScriptUrl(getCobrowsingScriptUrl(cdsData));

        const {
            scriptUrl = '',
            globalVariables,
        } = getWalkmeVariables(cdsData);

        setWalkmeScriptUrl(scriptUrl);
        const {
            userDateFormat = 'dd/mm/yyyy',
            userTimeFormat = 'h:mm AM/PM',
            userNumberFormat = '1,000.00',
        } = userPrefs || {};

        setWalkmeGlobals({
            ...globalVariables,
            userDateFormat,
            userTimeFormat,
            userNumberFormat,
            themeName,
        });

        // This looks strange, but at least for now, the "globals" for both
        // OSH providers are identical.  If these ever diverge, we will
        // need to setup an additional function to get EnableNow variables.
        setEnableNowGlobals(globalVariables);

        setLiveAgentConfig(getLiveAgentConfigVariables(cdsData));
        setLiveAgentUserConfig(getLiveAgentUserConfigVariables(cdsData));
    }, [cdsData]);

    const contextValue = useMemo(() => ({
        ...helpPortalDataContext,
        walkmeContext: {
            script: walkmeScriptUrl,
            loaded: walkmeBundleLoaded,
            hidden: isWalkmeHidden,
            globals: walkmeGlobals,
            concluded: walkmeConcluded,
            statusMessage: walkmeStatusMessage,
            contentExists: walkmeContentExists,
            setWalkmeContentExists,
            toggleWalkMeMenu: window?.WalkMePlayerAPI?.toggleMenu
                ? window?.WalkMePlayerAPI?.toggleMenu.bind()
                : undefined,
        },
        chatContext: {
            liveAgentConfig,
            liveAgentUserConfig,
            loaded: isLiveAgentChatLoaded,
            concluded: liveAgentChatConcluded,
            statusMessage: liveAgentChatStatusMessage,
            isChatAgentOnline,
            setIsChatAgentOnline,
            toggleChatWindow: configOptions?.liveChatEnabled
                && window?.liveagent?.startChat
                && liveAgentConfig?.chatId
                ? () => {
                    // there is something in the toJSON function that messes up
                    // LiveAgent Chat.  this is a hack, but we will just delete
                    // it long enough to get Chat started and then restore it
                    const backup = Array?.prototype?.toJSON;
                    try {
                        delete Array?.prototype?.toJSON;
                        window?.liveagent?.startChat(liveAgentConfig?.chatId);
                    } catch (err) {
                        logger.error(`Starting LiveAgent Chat: ${err.message}`, {
                            componentName: 'HelpPortal.HelpPortalAPIProvider.toggleChatWindow',
                        });
                    } finally {
                        // eslint-disable-next-line no-extend-native
                        Array.prototype.toJSON = backup;
                    }
                }
                : undefined,
        },
        cobrowsingContext: {
            loaded: isCobrowsingLoaded,
            concluded: cobrowsingConcluded,
            statusMessage: cobrowsingStatusMessage,
        },
        enableNowContext: {
            loaded: isEnableNowLoaded,
            busy: !enableNowWebAssistant,
            globals: enableNowGlobals,
            concluded: enableNowConcluded,
            statusMessage: enableNowStatusMessage,
            contentExists: enableNowContentExists,
            setEnableNowContentExists,
            toggleEnableNowMenu: enableNowWebAssistant
                ? enableNowWebAssistant?.toggle?.bind(enableNowWebAssistant)
                : undefined,
        },
        jouleContext: {
            loaded: isJouleScriptLoaded,
            busy: !jouleWebClient,
            jouleConfig,
            disabledByXpd: configOptions?.jouleDisabled,
            concluded: jouleConcluded,
            statusMessage: jouleStatusMessage,
        },
    }), [
        helpPortalDataContext,
        walkmeScriptUrl,
        walkmeBundleLoaded,
        isWalkmeHidden,
        walkmeGlobals,
        walkmeConcluded,
        walkmeStatusMessage,
        walkmeContentExists,
        liveAgentConfig,
        liveAgentUserConfig,
        isLiveAgentChatLoaded,
        liveAgentChatConcluded,
        liveAgentChatStatusMessage,
        isChatAgentOnline,
        configOptions,
        isCobrowsingLoaded,
        cobrowsingConcluded,
        cobrowsingStatusMessage,
        isEnableNowLoaded,
        enableNowWebAssistant,
        enableNowGlobals,
        enableNowConcluded,
        enableNowStatusMessage,
        enableNowContentExists,
        enableNowWebAssistant,
        isJouleScriptLoaded,
        jouleConcluded,
        jouleStatusMessage,
        jouleWebClient,
    ]);

    return (
        <HelpPortalContext.Provider value={contextValue}>
            {children}
        </HelpPortalContext.Provider>
    );
};

HelpPortalAPIProvider.propTypes = {
    children: PropTypes.any,
    autoLoadWalkme: PropTypes.bool,
    logger: PropTypes.any,
    userPrefs: PropTypes.object,
    jouleConfig: PropTypes.object,
};

HelpPortalAPIProvider.defaultProps = {
    autoLoadWalkme: true,
};

HelpPortalAPIProvider.displayName = 'HelpPortalAPIProvider';

export default HelpPortalAPIProvider;
