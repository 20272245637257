import { Promise } from 'bluebird';
import 'isomorphic-fetch';
import { v4 as uuidv4 } from 'uuid';
import { CORRELATION_ID_HEADER } from '../constants';

function getCorrelationIdFromHeader(response) {
    return response.headers.get(CORRELATION_ID_HEADER);
}

export function fetchFile(url) {
    return Promise.try(() => {
        const fetchOptions = {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
        };

        return fetch(url, fetchOptions);
    })
        .then((response) => response.json().then((responseBody) => ({
            response,
            responseBody,
        })))
        .then(({ response, responseBody }) => {
            const { status } = response;

            if (status >= 400) {
                throw new Error(`fetch call failed with status code ${status}`);
            }

            return {
                data: responseBody.data,
            };
        });
}

export default function fetchJson(apiContext, query, correlationId) {
    return Promise.try(() => {
        const {
            jwt,
            url,
        } = apiContext;

        const concurCorrelationId = correlationId || apiContext.correlationId || uuidv4();
        const fetchOptions = {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify(query),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                [CORRELATION_ID_HEADER]: concurCorrelationId,
                ...(typeof jwt === 'string' && { Authorization: `Bearer ${jwt}` }),
            },
        };

        // If the API context provides its own fetch function, use it
        // That allows fetchOverXhr to be provided and used when needed
        const fetchFunction = typeof apiContext.fetch === 'function' ? apiContext.fetch : fetch;

        return fetchFunction(url, fetchOptions);
    })
        .then((response) => response.json().then((responseBody) => ({
            response,
            responseBody,
        })))
        .then(({ response, responseBody }) => {
            const { status } = response;
            const correlationIdFromResponse = getCorrelationIdFromHeader(response);
            const error = responseBody.errors ? responseBody.errors?.[0] : responseBody.error;

            if (error || status >= 400) {
                let errorMessage = `CDS call failed with status code ${status}:`;
                const { message, errorCode } = error || {};
                if (errorCode) {
                    errorMessage += `, error code: ${errorCode}`;
                }
                if (correlationIdFromResponse) {
                    errorMessage += `, [correlationId: ${correlationIdFromResponse}]`;
                }
                if (message) {
                    errorMessage += ` ${message}`;
                }
                throw new Error(errorMessage);
            }

            return {
                data: responseBody.data,
                meta: {
                    correlationId: correlationIdFromResponse,
                },
            };
        });
}
