const initializeWalkme = ({
    isWalkmeTourInProgress,
    autoLoadWalkme,
    setWalkmeReady,
    setWalkmeHidden,
    setWalkmeContentExists,
}) => {
    // Walkme API
    // eslint-disable-next-line camelcase
    window.walkme_player_event = (event) => {
        switch (event.Type) {
        case 'AfterMenuClose':
            setWalkmeHidden(false);
            break;
        case 'PlayerInitComplete':
            setWalkmeReady();
            setWalkmeContentExists(!!window?.WalkMePlayerAPI?.hasVisibleContent?.());
            // Don't open window if tour is in progress or it's in auto load mode
            if (!isWalkmeTourInProgress && !autoLoadWalkme) {
                window?.WalkMePlayerAPI?.toggleMenu?.();
                setWalkmeHidden(true);
            }
            break;
        case 'WalkthruSelected':
            // Need to track when walk-through is in progress
            // so we know when to load bundle on page load
            window?.sessionStorage?.setItem?.('walkmeTourInProgress', 'true');
            break;
        default:
            break;
        }
    };

    // eslint-disable-next-line camelcase
    window.walkme_player_event = window.walkme_player_event.bind(this);

    // eslint-disable-next-line camelcase
    window.walkme_event = (event) => {
        switch (event.Type) {
        case 'UserStoppedWalkthru':
            // Walkthrough not in progress anymore
            window?.sessionStorage?.removeItem?.('walkmeTourInProgress');
            break;

        case 'WalkthruCompleted':
            // Walkthrough not in progress anymore
            window?.sessionStorage?.removeItem?.('walkmeTourInProgress');
            break;

        case 'FlowStarted':
            // Need to track when walk-through is in
            // progress so we know when to load bundle on page load
            window?.sessionStorage?.setItem?.('walkmeTourInProgress', 'true');
            break;

        case 'FlowStopped':
            // Smart Walkthrough not in progress anymore
            window?.sessionStorage?.removeItem?.('walkmeTourInProgress');
            break;

        case 'FlowCompleted':
            // Smart Walkthrough not in progress anymore
            window?.sessionStorage?.removeItem?.('walkmeTourInProgress');
            break;

        default:
            break;
        }
    };
};

export default initializeWalkme;
