import GridList from '@concur/nui-widgets/GridList';
import Button from '@concur/nui-widgets/Button';
import MessageInline from '@concur/nui-widgets/MessageInline';
import Spinner from '@concur/nui-widgets/Spinner';
import React from 'react';

const ApiStatus = ({
    apiName,
    concluded,
    loaded,
    statusMessage,
    busy,
    successContent,
    buttonProps,
}) => {
    const {
        disabled: buttonDisabled,
        ...otherButtonProps
    } = buttonProps || {};

    const getType = () => {
        if (!concluded) {
            return undefined;
        }

        if (loaded) {
            return 'success';
        }

        return 'warning';
    };

    const renderDetails = () => {
        if (!concluded) {
            return (
                <Spinner
                    message="Loading..."
                    size="lg"
                    type="inline"
                    visible
                />
            );
        }

        if (loaded) {
            return (
                <>
                    <MessageInline
                        title="Script loaded"
                        type="success"
                        typeLabel="Success"
                    />
                    {busy ? (
                        <Spinner
                            message="Busy, please wait..."
                            size="lg"
                            type="inline"
                            visible
                        />
                    ) : (
                        successContent
                    )}
                </>
            );
        }

        return (
            <MessageInline
                title="API Unavailable"
                subtitle={statusMessage}
                type="warning"
                typeLabel="Warning"
            />
        );
    };

    return (
        <GridList.Item
            headerToolbarProps={{
                title: apiName,
            }}
            headerToolbarActions={(
                <Button
                    {...otherButtonProps}
                    disabled={!concluded || !loaded || buttonDisabled}
                    type="link"
                >
                    Launch
                </Button>
            )}
            type={getType()}
        >
            {renderDetails()}
        </GridList.Item>
    );
};

export default ApiStatus;
