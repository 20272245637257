import React, { useContext } from 'react';
import HelpPortalContext from '../HelpPortal/HelpPortalContext';

const Joule = () => {
    const { jouleContext } = useContext(HelpPortalContext) || {};

    return (
        <div>
            <pre style={{
                overflow: 'auto',
            }}
            >
                helpPortalProps.jouleConfig:
                {
                    `${JSON.stringify(jouleContext?.jouleConfig, null, 2)}`
                }
            </pre>
            <pre style={{
                overflow: 'auto',
            }}
            >
                {`disabledByXpd: ${!!jouleContext?.disabledByXpd}`}
            </pre>
        </div>
    );
};

Joule.displayName = 'Joule';

export default Joule;
