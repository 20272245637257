import { getHelpPortalEnvironment, isAdminAuthorizedSupportContact } from '../utils/cdsUtils';
import { LIVEAGENT_CHAT_IDS, LIVEAGENT_DEPLOYMENT_ID, LIVEAGENT_ENV_CONFIGS } from './chatConstants';

export const isLiveAgentChatEnabled = isAdminAuthorizedSupportContact;

export const isChatEntryPointEnabled = (cdsData) => {
    if (!cdsData) {
        return false;
    }

    const {
        isAdmin,
        isStandardTrial,
        isStandardActivation,
    } = cdsData || {};

    return (
        isAdmin
        && (isStandardTrial || isStandardActivation)
    );
};

// Gets live agent config variables from cdsData
export const getLiveAgentConfigVariables = (cdsData) => {
    if (!cdsData) {
        return {};
    }

    const {
        liveAgentChatSwitch,
        isProfessional,
        isStandardTrial,
        isStandardActivation,
    } = cdsData;

    const env = getHelpPortalEnvironment(cdsData);

    let chatEnvironment = '';
    let chatProduct = '';

    if (env?.indexOf('prod') >= 0) {
        chatEnvironment = 'prod';
    } else {
        chatEnvironment = (liveAgentChatSwitch === 'dev') ? 'dev' : 'staging';
    }

    if (isProfessional) {
        chatProduct = 'professional';
    } else if (isStandardTrial) {
        chatProduct = 'standardTrial';
    } else if (isStandardActivation) {
        chatProduct = 'standardActivation';
    } else {
        chatProduct = 'standard';
    }

    const configs = LIVEAGENT_ENV_CONFIGS[chatEnvironment];

    return {
        scriptUrl: configs.scriptUrl,
        chatUrl: configs.chatUrl,
        orgId: configs.orgId,
        chatId: LIVEAGENT_CHAT_IDS[chatProduct],
        deploymentId: LIVEAGENT_DEPLOYMENT_ID,
    };
};

// Gets live agent user config variables from cdsData
export const getLiveAgentUserConfigVariables = (cdsData) => {
    if (!cdsData) {
        return {};
    }

    const {
        cesEntityID,
        userId,
        loginName,
        fullName,
        primaryEmail,
        lastName,
        firstName,
        cesLocale,
    } = cdsData;

    return {
        entityId: cesEntityID,
        userId,
        loginId: loginName,
        fullName,
        primaryEmail,
        lastName,
        firstName,
        locale: cesLocale,
    };
};
