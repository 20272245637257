const setupWalkme = ({
    cdhiUrl,
    walkmeGlobals,
}) => {
    // eslint-disable-next-line no-underscore-dangle
    window._walkmeConfig = {
        smartLoad: true,
        snippetManager: true,
        cdhiUrl,
    };

    // Walkme global variables
    window.walkme = window.walkme || {};
    window.walkme = {
        ...window.walkme,
        ...walkmeGlobals,
    };
};

export default setupWalkme;
