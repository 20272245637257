import classnamesBind from 'classnames/bind';
import NavMenu from '@concur/nui-widgets/lib/NavMenu/NavMenu';
import Separator from '@concur/nui-widgets/lib/Menu/Separator';
import Spinner from '@concur/nui-widgets/lib/Spinner/Spinner';
import PropTypes from 'prop-types';
import React from 'react';
import { getDataTestValue } from './helpMenuUtils';

const CSS_BLOCK = 'sapcnqr-help-menu';

const HelpMenuItem = ({
    anchorProps,
    disabled,
    iconComp,
    loadingMessage,
    name,
    onClick,
    show,
    id,
    styles,
    url: href,
    withDivider,
    ...menuItemProps
}) => {
    if (!show || (!name?.trim?.() && !loadingMessage)) {
        return null;
    }

    const classnames = classnamesBind.bind(styles);

    let interactionProps = {};
    let itemType = 'menuitem';
    const subItemClasses = classnames(
        `${CSS_BLOCK}__subitem`,
    );
    const itemTextClasses = classnames(
        `${CSS_BLOCK}__item-text`,
    );

    if (href?.trim?.()) {
        if (!name?.trim?.()) {
            return null;
        }
        interactionProps = {
            href,
        };
        itemType = 'anchor';
    } else if (typeof onClick === 'function') {
        interactionProps = {
            onKeyDown: (event) => {
                if (event && (event.keyCode === 13 || event.keyCode === 32)) {
                    onClick?.(event);
                }
            },
            onClick,
        };
        itemType = 'button';
    }

    return (
        <>
            {withDivider && <Separator />}
            <NavMenu.Item
                {...menuItemProps}
                {...interactionProps}
                data-test={getDataTestValue(id, itemType)}
                disabled={disabled}
                linkProps={{
                    ...anchorProps,
                    className: subItemClasses,
                    innerTextClasses: itemTextClasses,
                }}
                className={
                    classnames(
                        `${CSS_BLOCK}__menu-item`,
                        {
                            [`${CSS_BLOCK}__menu-item--disabled`]: disabled,
                            [`${CSS_BLOCK}__item-text`]: disabled,
                        },
                    )
                }
                label={(
                    <>
                        {iconComp}
                        {name}
                        {loadingMessage && (
                            <Spinner
                                message={loadingMessage}
                                size="lg"
                                type="inline"
                                visible
                                className={
                                    classnames(
                                        `${CSS_BLOCK}__menu-item`,
                                    )
                                }
                            />
                        )}
                    </>
                )}
            />
        </>
    );
};

HelpMenuItem.propTypes = {
    anchorProps: PropTypes.object,
    disabled: PropTypes.bool,
    iconComp: PropTypes.node,
    loadingMessage: PropTypes.string,
    name: PropTypes.string,
    onClick: PropTypes.func,
    show: PropTypes.bool,
    withDivider: PropTypes.bool,
    id: PropTypes.string,
    url: PropTypes.string,
    styles: PropTypes.object,
};
HelpMenuItem.defaultProps = {
    iconComp: null,
    show: true,
};
HelpMenuItem.displayName = 'HelpMenuItem';
export default HelpMenuItem;
