import hoistNonReactStatics from 'hoist-non-react-statics';
import React from 'react';
import { GATEWAY_BRANDING_ID } from '@concur/core-ui-shell/lib/constants';

export default (WrappedComponent) => {
    const Component = (props) => (
        <WrappedComponent
            {...props}
            cssFilenamePrefix="help-portal"
        />
    );

    hoistNonReactStatics(Component, WrappedComponent);

    Component.defaultProps = {
        brandingId: GATEWAY_BRANDING_ID,
    };
    Component.propTypes = WrappedComponent.propTypes;
    Component.displayName = WrappedComponent.displayName || WrappedComponent.name || 'withStylesPlaceholder';

    return Component;
};
