import { parseCESEntitySettings } from '../utils/cdsUtils';

export const getEnableNowConfig = (cdsData) => {
    if (!cdsData) {
        return {};
    }
    const {
        cesEntitySettings,
    } = cdsData;

    if (!cesEntitySettings?.trim()) {
        return {};
    }

    const {
        SETUP_WIZARD_FEATURE: setupWizardFeature,
    } = parseCESEntitySettings(cesEntitySettings) || {};

    const enableSelfEducation = setupWizardFeature?.ENABLE_SELF_EDUCATION;

    const educationProvider = setupWizardFeature?.SELF_EDUCATION_PROVIDER;

    // there is no generic EnableNow script/content so if the company doesn't have
    // a defined WebAssistant URL, there is no fallback
    const enableNowWebAssistantUrl = setupWizardFeature?.ENABLE_NOW_WEB_ASSISTANT_URL;

    return {
        educationProvider,
        senSelfEducationEnabled: !!(
            enableSelfEducation
            && educationProvider === 'SEN'
            && enableNowWebAssistantUrl?.trim?.()
        ),
        enableNowWebAssistantUrl,
    };
};

export const getEnableNowTheme = (themeName) => {
    // EnableNow's basic premise is that the WebAssistant theme
    // shouldbe opposite of the main application's theme
    // (dark for light, light for dark, etc.)
    switch (themeName) {
    case 'sap_horizon_hcw':
        return 'hcb';
    case 'sap_horizon_hcb':
        return 'hcw';
    case 'sap_horizon_dark':
        return 'horizon';
    default:
        // sap_horizon
        return 'horizondark';
    }
};
