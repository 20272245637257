import { isOuttaskCompany, isAdminAuthorizedSupportContact } from '../utils/cdsUtils';
import {
    GLANCE_PRODUCTION_SCRIPT_URL,
    GLANCE_PRODUCTION_SITE,
    GLANCE_STAGING_SCRIPT_URL,
} from './cobrowsingConstants';

/**
 * Returns whether or not a on screen help should be disabled for a user
 */
export const isCobrowsingEnabled = (cdsData) => {
    if (!cdsData) {
        return false;
    }

    const doesHaveSupportLink = !!cdsData?.supportLink;

    return (
        isOuttaskCompany(cdsData)
        || isAdminAuthorizedSupportContact(cdsData)
        || doesHaveSupportLink
    );
};

/**
 * Gets Cobrowsing environment from CDS env variable in cdsData.
 */
export const getCobrowsingEnvironment = (cdsData) => {
    const { env } = cdsData || {};
    return env === 'integration' ? 'staging' : 'production';
};

/**
 * Gets Cobrowsing script URL from CDS env variable in cdsData.
 */
export const getCobrowsingScriptUrl = (cdsData) => (
    getCobrowsingEnvironment(cdsData) === GLANCE_PRODUCTION_SITE
        ? GLANCE_PRODUCTION_SCRIPT_URL
        : GLANCE_STAGING_SCRIPT_URL
);
