import classnamesBind from 'classnames/bind';
import compose from '@concur/core-ui-shell/lib/utils/compose';
import { withFormatter } from '@concur/nui-intl-runtime';
import Button from '@concur/nui-widgets/Button';
import Icon from '@concur/nui-widgets/Icon';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './DebugToggle.css';

const CSS_BLOCK = 'sapcnqr-debug-toggle';

const DebugToggle = ({
    expanded,
    formatter,
    onClick,
}) => {
    const classnames = classnamesBind.bind(styles);
    return (
        <Button
            aria-label={formatter.formattedMessage({
                id: expanded ? 'CoreUI.HelpPortal.hideHelpContent' : 'CoreUI.HelpPortal.showHelpContent',
            })}
            className={classnames(CSS_BLOCK)}
            muted={expanded}
            onClick={onClick}
        >
            Debug Panel
            <Icon ariaHidden iconName={expanded ? 'close' : 'question-mark'} size="lg" />
        </Button>
    );
};

DebugToggle.displayName = 'DebugToggle';

DebugToggle.propTypes = {
    expanded: PropTypes.bool,
    onClick: PropTypes.func,
    // set by the withFormatter HOC
    formatter: PropTypes.any,
};

export default compose(
    withFormatter,
)(DebugToggle);
