import compose from '@concur/core-ui-shell/lib/utils/compose';
import classnamesBind from 'classnames/bind';
import { withFormatter } from '@concur/nui-intl-runtime';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import Transition, {
    ENTERED,
    ENTERING,
    EXITED,
    EXITING,
} from 'react-transition-group/Transition';
import Button from '@concur/nui-widgets/lib/Buttons/Button';
import Icon from '@concur/nui-widgets/lib/Icon/Icon';
import HelpPortalContext from '../HelpPortal/HelpPortalContext';
import ChatEntryPointIcon from './ChatEntryPointIcon';
import { isChatEntryPointEnabled } from './chatUtils';
import styles from './ChatEntryPoint.css';

const CSS_BLOCK = 'sapcnqr-chat-entry-point';

const ChatEntryPoint = ({
    formatter,
}) => {
    const classnames = classnamesBind.bind(styles);
    const [isClosed, setIsClosed] = useState(false);

    const { cdsData, chatContext } = useContext(HelpPortalContext) || {};

    const {
        loaded: isLiveAgentChatLoaded,
        concluded: liveAgentChatConcluded,
        isChatAgentOnline,
        toggleChatWindow,
    } = chatContext || {};

    // this chat entry point has special rules for when it is shown
    // chat might be enabled, but this entry point might not be shown
    const showChatEntryPoint = !!(
        isLiveAgentChatLoaded
        && liveAgentChatConcluded
        && typeof toggleChatWindow === 'function'
        && isChatEntryPointEnabled(cdsData)
    );

    if (!showChatEntryPoint) {
        return null;
    }

    return (
        <Transition
            in={!isClosed}
            timeout={300}
            data-element="chat-entry-point-transition"
        >
            {
                (transitionState) => (
                    <div
                        className={classnames(
                            CSS_BLOCK,
                            {
                                [`${CSS_BLOCK}--visible`]: transitionState === ENTERED || transitionState === EXITING,
                                [`${CSS_BLOCK}--hidden`]: transitionState === ENTERING || transitionState === EXITED,
                            },
                        )}
                    >
                        <Button
                            allowFocusOnDisable
                            className={classnames(`${CSS_BLOCK}__button`)}
                            disabled={!isChatAgentOnline}
                            disabledMessage={formatter.formattedMessage({ id: 'CoreUI.HelpPortal.agentUnavailable' })}
                            enabledMessage={formatter.formattedMessage({ id: 'CoreUI.HelpPortal.chatWithSupport' })}
                            onClick={toggleChatWindow}
                            aria-labelledby="chat-entry-point-text"
                            aria-hidden={transitionState === EXITED}
                            data-element="chat-entry-point-button"
                            innerTextClasses={classnames(`${CSS_BLOCK}__button-text`)}
                            type="primary"
                        >
                            <ChatEntryPointIcon
                                aria-hidden
                                data-element="chat-entry-point-icon"
                            />
                            <span
                                className={classnames(`${CSS_BLOCK}__text`)}
                                id="chat-entry-point-text"
                                data-element="chat-entry-point-text"
                            >
                                {formatter.formattedMessage({
                                    id: isChatAgentOnline
                                        ? 'CoreUI.HelpPortal.chatWithSupport'
                                        : 'CoreUI.HelpPortal.agentUnavailable',
                                })}
                            </span>
                        </Button>
                        <Button
                            className={classnames(`${CSS_BLOCK}__close`)}
                            onClick={() => setIsClosed(true)}
                            aria-label={formatter.formattedMessage({ id: 'CoreUI.close' })}
                            data-element="chat-entry-point-close-button"
                            muted
                        >
                            <Icon ariaHidden iconName="close" size="sm" />
                        </Button>
                    </div>
                )
            }
        </Transition>
    );
};

ChatEntryPoint.propTypes = {
    // set by the withFormatter HOC
    formatter: PropTypes.any,
};

ChatEntryPoint.defaultProps = {};

ChatEntryPoint.displayName = 'ChatEntryPoint';

export default compose(
    withFormatter,
)(ChatEntryPoint);
