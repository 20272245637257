import compose from '@concur/core-ui-shell/lib/utils/compose';
import { withFormatter } from '@concur/nui-intl-runtime';
import classnamesBind from 'classnames/bind';
import IconButton from '@concur/nui-widgets/IconButton';
import NavMenu from '@concur/nui-widgets/NavMenu';
import Separator from '@concur/nui-widgets/Separator';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import ChatIcon from '../Chat/ChatIcon';
import EmailIcon from '../Support/EmailIcon';
import SpeakIcon from '../Support/SpeakIcon';
import SupportModal from '../Support/SupportModal';
import CobrowsingIcon from '../Cobrowsing/CobrowsingIcon';
import {
    CALL_SUPPORT_MENUITEM_ID,
    CHAT_MENUITEM_ID,
    COBROWSE_MENUITEM_ID,
    COMMUNITY_MENUITEM_ID,
    EMAIL_SUPPORT_MENUITEM_ID,
    ENABLE_NOW_MENUITEM_ID,
    WALKME_MENUITEM_ID,
} from '../constants';
import HelpPortalContext from '../HelpPortal/HelpPortalContext';
import WalkmeIcon from '../WalkMe/WalkmeIcon';
import styles from './HelpMenu.css';
import HelpMenuGroup from './HelpMenuGroup';
import HelpMenuItem from './HelpMenuItem';
import HelpMenuToggle from './HelpMenuToggle';
import {
    getAdminHelpItems,
    getDataTestValue,
    getProductHelpItems,
    getOpenTranslationToolItem,
    getContactSupportItem,
} from './helpMenuUtils';
import FirstChildPortal from './_FirstChildPortal';

const CSS_BLOCK = 'sapcnqr-help-menu';

const HelpMenu = ({
    formatter,
    menuData,
    utilityMenu,
}) => {
    const classnames = classnamesBind.bind(styles);
    const [loadingAssistance, setLoadingAssistance] = useState(true);
    const [supportModalType, setSupportModalType] = useState();
    const [initializingJoule, setInitializingJoule] = useState(false);
    const [jouleActive, setJouleActive] = useState(false);

    const {
        helpMenuItem,
    } = menuData || {};

    const {
        walkmeContext,
        chatContext,
        cobrowsingContext,
        enableNowContext,
        callSupportContext,
        emailSupportContext,
        jouleContext,
    } = useContext(HelpPortalContext) || {};

    const {
        loaded: walkmeBundleLoaded,
        concluded: walkmeConcluded,
        hidden: walkmeHidden,
        contentExists: walkmeContentExists,
        toggleWalkMeMenu,
    } = walkmeContext || {};

    const {
        loaded: isLiveAgentChatLoaded,
        concluded: liveAgentChatConcluded,
        isChatAgentOnline,
        toggleChatWindow,
    } = chatContext || {};

    const {
        loaded: isCobrowsingLoaded,
        concluded: cobrowsingConcluded,
    } = cobrowsingContext || {};

    const {
        loaded: isEnableNowLoaded,
        concluded: enableNowConcluded,
        isEnableNowBusy,
        contentExists: enableNowContentExists,
        toggleEnableNowMenu,
    } = enableNowContext || {};

    const {
        show: showCallSupportItem,
        supportType: callSupportType,
    } = callSupportContext || {};

    const {
        show: showEmailSupportItem,
        supportType: emailSupportType,
    } = emailSupportContext || {};

    const {
        loaded: jouleLoaded,
        concluded: jouleConcluded,
    } = jouleContext || {};

    useEffect(() => {
        setLoadingAssistance(
            !(
                walkmeConcluded
                && enableNowConcluded
                && liveAgentChatConcluded
                && cobrowsingConcluded
            ),
        );
    }, [
        walkmeConcluded,
        liveAgentChatConcluded,
        cobrowsingConcluded,
        enableNowConcluded,
    ]);

    const showCobrowseItem = !!(
        cobrowsingConcluded
        && isCobrowsingLoaded
        && window?.GLANCE?.Cobrowse?.Visitor?.showTerms
        && typeof window.GLANCE.Cobrowse.Visitor.showTerms === 'function'
    );

    const showEnableNowItem = !!(
        enableNowConcluded
        && isEnableNowLoaded
        && enableNowContentExists
        && typeof toggleEnableNowMenu === 'function'
    );

    const showWalkmeItem = !!(
        walkmeConcluded
        && walkmeBundleLoaded
        && !walkmeHidden
        && walkmeContentExists
        && !showEnableNowItem
        && typeof toggleWalkMeMenu === 'function'
    );

    const showChatItem = !!(
        isLiveAgentChatLoaded
        && liveAgentChatConcluded
        && typeof toggleChatWindow === 'function'
    );

    const showJouleMenuItem = !!(
        jouleConcluded
        && jouleLoaded
        && utilityMenu
    );

    const openTranslationToolItem = getOpenTranslationToolItem(helpMenuItem);

    const contactSupportItem = getContactSupportItem(helpMenuItem);

    const productItems = getProductHelpItems(helpMenuItem);
    const adminItems = getAdminHelpItems(helpMenuItem);
    const ID = 'help-portal-menu-toggle';

    const getIdBasedProps = (itemId) => ({
        id: itemId,
        anchorProps: {
            'data-analytics-json': `{"applicationName":"helpPortalAssistance","elementPath":"${itemId}","action":"click","elementType":"menuitem"}`,
        },
    });

    const assistanceAvailable = (
        loadingAssistance
        || showWalkmeItem
        || showEnableNowItem
        || showChatItem
        || showCallSupportItem
        || showEmailSupportItem
        || showCobrowseItem
    );

    const handleJouleToggle = () => {
        if (jouleActive) {
            window.sap?.das?.webclient?.hide?.();
            setJouleActive(false);
            return;
        }

        window.sap?.das?.webclient?.show?.();

        const caiWebclientMain = document?.getElementById?.('cai-webclient-main');
        if (caiWebclientMain) {
        // Override the z-index of the webclient main div to ensure the help menu is always on top
        // as the webclient is draggable
            caiWebclientMain.style.zIndex = '1000';
        }

        if (initializingJoule) {
            return;
        }

        setInitializingJoule(true);

        let attempts = 0;
        const maxAttempts = 600; // 1 minute / 100ms = 600 attempts

        const checkInterval = setInterval(() => {
            attempts += 1;
            try {
                if (window.sap?.das?.webclient?.isLoaded?.()) {
                    clearInterval(checkInterval);
                    setJouleActive(true);
                    setInitializingJoule(false);
                } else if (attempts >= maxAttempts) {
                    clearInterval(checkInterval);
                    setInitializingJoule(false);
                }
            } catch (error) {
                clearInterval(checkInterval);
                setInitializingJoule(false);
            }
        }, 100);
    };

    return (
        <>
            {
                showJouleMenuItem
                && (
                    <FirstChildPortal
                        containerTag="li"
                        className={classnames(`${CSS_BLOCK}__joule-menu-item`)}
                        container={utilityMenu}
                    >
                        <IconButton
                            className={classnames(`${CSS_BLOCK}__joule-toggle`, {
                                [`${CSS_BLOCK}__joule-toggle--on`]: jouleActive,
                            })}
                            disabled={initializingJoule}
                            iconName={jouleActive ? 'da-2' : 'da'}
                            ariaLabel="Joule"
                            onClick={handleJouleToggle}
                            role="menuitem"
                            tooltipProps={{
                                delay: 1000,
                                placement: ['bottom', 'top', 'right', 'left'],
                                outerPopperProps: {
                                    showArrow: false,
                                    offset: 2,
                                },
                                innerContainerProps: {
                                    className: classnames(`${CSS_BLOCK}__tooltip-inner`),
                                },
                            }}
                        />
                    </FirstChildPortal>
                )
            }
            <HelpMenuToggle
                styles={styles}
                className={classnames(
                    `${CSS_BLOCK}__toggle`,
                )}
                id={ID}
                data-test={getDataTestValue(ID, 'button')}
            >
                {assistanceAvailable && (
                    <NavMenu.Group
                        header={{
                            title: formatter.formattedMessage({ id: 'CoreUI.HelpPortal.getAssistance' }),
                            id: 'getAssistance',
                        }}
                    >
                        {loadingAssistance
                        && (
                            <HelpMenuItem
                                show
                                styles={styles}
                                loadingMessage={formatter.formattedMessage({ id: 'CoreUI.loading' })}
                            />
                        )}
                        <HelpMenuItem
                            show={showWalkmeItem}
                            styles={styles}
                            name={formatter.formattedMessage({ id: 'CoreUI.HelpPortal.exploreWalkthroughs' })}
                            onClick={toggleWalkMeMenu}
                            iconComp={<WalkmeIcon styles={styles} />}
                            {...getIdBasedProps(WALKME_MENUITEM_ID)}
                        />
                        <HelpMenuItem
                            show={showEnableNowItem}
                            styles={styles}
                            name={formatter.formattedMessage({ id: 'CoreUI.HelpPortal.exploreWalkthroughs' })}
                            disabled={isEnableNowBusy}
                            onClick={toggleEnableNowMenu}
                            iconComp={<WalkmeIcon styles={styles} />}
                            {...getIdBasedProps(ENABLE_NOW_MENUITEM_ID)}
                        />
                        <HelpMenuItem
                            show={showChatItem}
                            styles={styles}
                            name={formatter.formattedMessage({
                                id: isChatAgentOnline
                                    ? 'CoreUI.HelpPortal.chatWithSupport'
                                    : 'CoreUI.HelpPortal.agentUnavailable',
                            })}
                            disabled={!isChatAgentOnline}
                            onClick={isChatAgentOnline ? toggleChatWindow : undefined}
                            iconComp={<ChatIcon styles={styles} />}
                            {...getIdBasedProps(CHAT_MENUITEM_ID)}
                        />
                        <HelpMenuItem
                            styles={styles}
                            show={showCallSupportItem}
                            name={formatter.formattedMessage({ id: 'CoreUI.HelpPortal.speakWithSupport' })}
                            onClick={() => {
                                setSupportModalType(callSupportType);
                            }}
                            iconComp={<SpeakIcon styles={styles} />}
                            {...getIdBasedProps(CALL_SUPPORT_MENUITEM_ID)}
                        />
                        <HelpMenuItem
                            styles={styles}
                            show={showEmailSupportItem}
                            name={formatter.formattedMessage({ id: 'CoreUI.HelpPortal.emailSupport' })}
                            onClick={() => {
                                setSupportModalType(emailSupportType);
                            }}
                            iconComp={<EmailIcon styles={styles} />}
                            {...getIdBasedProps(EMAIL_SUPPORT_MENUITEM_ID)}
                        />
                        <HelpMenuItem
                            styles={styles}
                            show={showCobrowseItem}
                            name={formatter.formattedMessage({ id: 'CoreUI.HelpPortal.startCoBrowsingSession' })}
                            onClick={window?.GLANCE?.Cobrowse?.Visitor?.showTerms}
                            iconComp={<CobrowsingIcon styles={styles} />}
                            {...getIdBasedProps(COBROWSE_MENUITEM_ID)}
                        />
                    </NavMenu.Group>
                )}
                <HelpMenuGroup
                    styles={styles}
                    withDivider={assistanceAvailable}
                    name={formatter.formattedMessage({ id: 'CoreUI.HelpPortal.productResource' })}
                    items={productItems}
                    path="HelpMenu"
                />
                <HelpMenuGroup
                    withDivider
                    styles={styles}
                    name={formatter.formattedMessage({ id: 'CoreUI.HelpPortal.adminResource' })}
                    items={adminItems}
                    path="HelpMenu"
                />
                <Separator />
                <HelpMenuItem
                    styles={styles}
                    name={formatter.formattedMessage({ id: 'CoreUI.HelpPortal.askCommunity' })}
                    url="https://community.concur.com/"
                    id={COMMUNITY_MENUITEM_ID}
                    anchorProps={{
                        target: '_blank',
                        'data-analytics-json': `{"applicationName":"helpPortalAssistance","elementPath":"${COMMUNITY_MENUITEM_ID}","action":"click","elementType":"link"}`,
                    }}
                />
                <HelpMenuItem
                    {...contactSupportItem}
                    styles={styles}
                    name={contactSupportItem
                        ? formatter.formattedMessage({ id: 'CoreUI.contactSupport' })
                        : undefined}
                    target={null}
                />
                <HelpMenuItem
                    {...openTranslationToolItem}
                    styles={styles}
                />
            </HelpMenuToggle>
            <SupportModal
                onHide={() => setSupportModalType()}
                show={!!supportModalType}
                supportType={supportModalType}
            />
        </>
    );
};

HelpMenu.propTypes = {
    brandingId: PropTypes.number,
    // set by the withFormatter HOC
    formatter: PropTypes.any,
    menuData: PropTypes.shape({
        helpMenuItem: PropTypes.any,
    }),
    utilityMenu: PropTypes.any,
    themeName: PropTypes.string,
};

HelpMenu.defaultProps = {};

HelpMenu.displayName = 'HelpMenu';

export default compose(
    withFormatter,
)(HelpMenu);
