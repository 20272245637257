import { parseCESEntitySettings, isAdminAuthorizedSupportContact } from '../utils/cdsUtils';
import {
    CALL_SUPPORT_FREE_TRIAL,
    CALL_SUPPORT_NO_SERVICE_ASSISTANT,
    CALL_SUPPORT_SERVICE_ASSISTANT_PROFESSIONAL,
    CALL_SUPPORT_SERVICE_ASSISTANT_STANDARD,
    EMAIL_SUPPORT_FREE_TRIAL,
    EMAIL_SUPPORT_SERVICE_ASSISTANT,
} from '../constants';

export const isCallAndEmailSupportEnabled = (cdsData) => {
    if (!cdsData) {
        return false;
    }

    const doesHaveSupportLink = !!cdsData?.supportLink;

    return (
        isAdminAuthorizedSupportContact(cdsData)
        || doesHaveSupportLink
    );
};

export const getCallSupportType = (cdsData) => {
    if (!cdsData) {
        return null;
    }

    const {
        isStandard,
        isStandardTrial,
        isProfessional,
        cesEntitySettings = '',
    } = cdsData;

    if (!isAdminAuthorizedSupportContact(cdsData)) {
        return null;
    }

    if (isStandardTrial) {
        return CALL_SUPPORT_FREE_TRIAL;
    }

    const entitySettingsObj = parseCESEntitySettings(cesEntitySettings) || {};
    const setupWizardSettings = entitySettingsObj?.SETUP_WIZARD_FEATURE || {};
    const serviceAssistantEnabled = setupWizardSettings?.ENABLE_SERVICE_ASSISTANT || false;

    if (serviceAssistantEnabled) {
        if (isProfessional) {
            return CALL_SUPPORT_SERVICE_ASSISTANT_PROFESSIONAL;
        }
        if (isStandard) {
            return CALL_SUPPORT_SERVICE_ASSISTANT_STANDARD;
        }
    } else {
        return CALL_SUPPORT_NO_SERVICE_ASSISTANT;
    }

    return null;
};

export const getEmailSupportType = (cdsData) => {
    if (!cdsData) {
        return null;
    }

    const {
        isStandardTrial,
        cesEntitySettings = '',
    } = cdsData;

    if (!isAdminAuthorizedSupportContact(cdsData)) {
        return null;
    }

    const entitySettingsObj = parseCESEntitySettings(cesEntitySettings) || {};
    const setupWizardSettings = entitySettingsObj?.SETUP_WIZARD_FEATURE || {};
    const serviceAssistantEnabled = setupWizardSettings?.ENABLE_SERVICE_ASSISTANT || false;

    if (isStandardTrial) {
        return EMAIL_SUPPORT_FREE_TRIAL;
    }

    if (serviceAssistantEnabled) {
        return EMAIL_SUPPORT_SERVICE_ASSISTANT;
    }

    return null;
};
