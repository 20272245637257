import { getEnableNowTheme } from './enableNowUtils';

const setupEnableNow = ({
    themeName,
    lang,
    setEnableNowWebAssistant,
    setEnableNowContentExists,
    enableNowGlobals,
}) => {
    window.web_assistant_config = {
        theme: getEnableNowTheme(themeName),
        language: lang,
        onWebAssistantLoaded: setEnableNowWebAssistant,
        onHelpAvailable: setEnableNowContentExists,
    };

    // EnableNow global variables
    window.enableNow = window.enableNow || {};
    window.enableNow = {
        ...window.enableNow,
        ...enableNowGlobals,
    };
};

export default setupEnableNow;
