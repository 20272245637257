import Cookies from 'universal-cookie';
import {
    getHelpPortalEnvironment,
    parseCESEntitySettings,
    getRoleList,
    getUIPermissions,
} from '../utils/cdsUtils';
import { WALKME_EU_PROD_SCRIPT_URL, WALKME_PROD_SCRIPT_URL, WALKME_QA_SCRIPT_URL } from './walkmeConstants';

export const getProductSettingsUIStateFromCookie = (setupWizardSettings = {}) => {
    const cookies = new Cookies();
    const cookieValue = cookies.get('productSettingsUIState');
    let cookieData = {};

    if (typeof cookieValue !== 'undefined') {
        // if cookieValue exists but the JSON is malformed,
        // default to the Entity Setting values SIMPLE_SETUP_WIZARD_EXP/INV,
        // so as to not change what the user is accustomed to seeing
        cookieData = {
            showBasicExpenseSettings: (typeof cookieValue.showAdvancedExpenseSettings !== 'undefined') ? !cookieValue.showAdvancedExpenseSettings : !!setupWizardSettings.SIMPLE_SETUP_WIZARD_EXP,
            showBasicInvoiceSettings: (typeof cookieValue.showAdvancedInvoiceSettings !== 'undefined') ? !cookieValue.showAdvancedInvoiceSettings : !!setupWizardSettings.SIMPLE_SETUP_WIZARD_INV,
        };
    } else {
        // if the cookieValue is undefined, default to the
        // Entity Setting values SIMPLE_SETUP_WIZARD_EXP/INV,
        // so as to not change what the user is accustomed to seeing
        cookieData = {
            showBasicExpenseSettings: !!setupWizardSettings.SIMPLE_SETUP_WIZARD_EXP,
            showBasicInvoiceSettings: !!setupWizardSettings.SIMPLE_SETUP_WIZARD_INV,
        };
    }

    return cookieData;
};

/**
 * Gets the walkme config from entity settings
 * @param {object} cdsData - CDS data
 * @returns {object} walkme variables
 */
export const getWalkMeConfig = (cdsData) => {
    if (!cdsData) {
        return {};
    }
    const {
        cesEntitySettings,
    } = cdsData;

    if (!cesEntitySettings?.trim()) {
        return {};
    }

    const {
        SETUP_WIZARD_FEATURE: setupWizardFeature,
    } = parseCESEntitySettings(cesEntitySettings) || {};

    const enableSelfEducation = setupWizardFeature?.ENABLE_SELF_EDUCATION;

    // some older entities may not have a self education provider
    // those are assumed to be WalkMe ('WM')
    const educationProvider = setupWizardFeature?.SELF_EDUCATION_PROVIDER || 'WM';

    return {
        educationProvider,
        walkmeSelfEducationEnabled: !!(
            enableSelfEducation
            && educationProvider === 'WM'
        ),
    };
};

/**
 * Gets walkme variables from CDS data
 * @param {object} cdsData - CDS data
 * @returns {object} walkme variables
 */
export const getWalkmeVariables = (cdsData) => {
    if (!cdsData) {
        return {};
    }

    const {
        accountingSettings = {},
        activationWizardLandingPage = '',
        activationWizardStatus = '',
        prodOffering = '',
        entityImplStatusCode = '',
        lang = '',
        cuuid = '',
        analyticsId = '',
        entityActivationType = '',
        breezeWSAccountSoftwareCode = '',
        companyUUID = '',
        expenseCountryCode = '',
        countryPacks = [],
        expenseGroupName = '',
        expensePolicyNames = [],
        isAdmin = false,
        cesEntitySettings = '',
        tripLinkEnabledForCompany,
        env = '',
    } = cdsData;

    const entitySettingsObj = parseCESEntitySettings(cesEntitySettings) || {};
    const setupWizardSettings = entitySettingsObj?.SETUP_WIZARD_FEATURE || {};
    const japanPublicTransportEnabled = entitySettingsObj?.SITE?.ENABLE_JPY_PUB_TRANS || false;
    const uiPermissions = getUIPermissions(cdsData);
    const areaPermissions = uiPermissions.Areas || {};
    const roleList = getRoleList(cdsData);
    const isAuthorizedSupportContact = roleList.indexOf('ClientCentralAdmin') !== -1;
    const isTripLinkUser = roleList.indexOf('OpenBookingUser') !== -1;
    const productSettingsUIState = getProductSettingsUIStateFromCookie(setupWizardSettings);
    const activationWizardEnabled = ['CW', 'FRE'].includes(activationWizardStatus);

    const globalVariables = {
        activationWizard: {
            activationWizardLandingPage,
            activationWizardStatus,
        },
        prodOffering,
        isAuthorizedSupportContact,
        // if Activation Wizard is enabled, basic Expense settings is enforced
        simpleSetupExpense: activationWizardEnabled
            ? true : productSettingsUIState.showBasicExpenseSettings,
        simpleSetupInvoice: productSettingsUIState.showBasicInvoiceSettings,
        hasExpense: !!areaPermissions.HasExpense,
        hasInvoice: !!areaPermissions.HasInvoice,
        implementationStatusCode: entityImplStatusCode,
        language: lang,
        serviceAssistant: !!setupWizardSettings.ENABLE_SERVICE_ASSISTANT,
        accountingSoftware: breezeWSAccountSoftwareCode,
        connectedToQuickBooks:
            (breezeWSAccountSoftwareCode === 'INTS' || breezeWSAccountSoftwareCode === 'INTQ')
            && !!accountingSettings?.AISConnected,
        connectedToSage: breezeWSAccountSoftwareCode === 'SGON'
            && !!accountingSettings?.AISConnected,
        connectedToXero: breezeWSAccountSoftwareCode === 'XERO'
            && !!accountingSettings?.AISConnected,
        CUUID: cuuid,
        AnalyticsId: analyticsId,
        activationType: entityActivationType,
        hasTravel: !!areaPermissions.HasTravel,
        hasPurchaseRequest: !!areaPermissions.HasPurchaseRequest,
        hasTravelRequest: !!areaPermissions.HasTravelRequest
            || !!areaPermissions.HasTravelRequestNUI,
        companyUUID,
        expenseCountryCode,
        countryPacks,
        expenseGroupName,
        expensePolicyNames,
        japanPublicTransportEnabled,
        roleType: isAdmin ? 'admin' : 'user',
        showTripLinkContent: tripLinkEnabledForCompany && isTripLinkUser,
        dataCenter: env,
    };

    const hpEnv = getHelpPortalEnvironment(cdsData);
    let scriptUrl = '';

    switch (hpEnv) {
    case 'prod_eu':
        scriptUrl = WALKME_EU_PROD_SCRIPT_URL;
        break;
    case 'prod':
        scriptUrl = WALKME_PROD_SCRIPT_URL;
        break;
    default:
        scriptUrl = WALKME_QA_SCRIPT_URL;
        break;
    }

    return {
        scriptUrl,
        globalVariables,
    };
};
