import React, { useContext } from 'react';
import HelpPortalContext from '../HelpPortal/HelpPortalContext';

const Chat = () => {
    const { chatContext } = useContext(HelpPortalContext) || {};
    const { liveAgentConfig, liveAgentUserConfig } = chatContext || {};

    return (
        <div>
            <pre style={{
                overflow: 'auto',
            }}
            >
                liveAgentConfig:
                {
                    `${JSON.stringify(liveAgentConfig, null, 2)}`
                }
                <br />
                liveAgentUserConfig:
                {
                    `${JSON.stringify(liveAgentUserConfig, null, 2)}`
                }
            </pre>
        </div>
    );
};

Chat.displayName = 'Chat';

export default Chat;
