import { OUTTASK_COMPANY_ID } from '../constants';

/**
 * Transforms a string of CES Entity Settings into an object
 * @param {object} settingsString - string containing CES Entity
 * Settings in the following format DOMAIN|SETTING|Y/N\r\n...
 * @returns {object} object with DOMAIN key containing objects with SETTING key and values
 */
export const parseCESEntitySettings = (settingsString = '') => {
    const settings = {};

    if (settingsString) {
        settingsString.split('\r\n').forEach((settingString) => {
            const [
                category = 'XX',
                property = 'XX',
                value,
            ] = settingString.split('|');

            // Initialize domain object if it doesn't exist
            settings[category] = settings[category] || {};

            // not all settings are booleans so only do boolean conversions when appropriate
            if (value === 'Y' || value === 'N') {
                settings[category][property] = value === 'Y';
            } else {
                settings[category][property] = value;
            }
        });
    }
    return settings;
};

/**
 * Checks if the self education entity setting is enabled
 * (regardless of provider)
 * @param {object} cdsData - CDS data
 * @returns {boolean}
 */
export const isSelfEducationEnabled = (cdsData) => {
    if (!cdsData) {
        return false;
    }
    const {
        cesEntitySettings,
    } = cdsData;

    if (!cesEntitySettings?.trim()) {
        return false;
    }

    const {
        SETUP_WIZARD_FEATURE: setupWizardFeature,
    } = parseCESEntitySettings(cesEntitySettings) || {};

    return !!setupWizardFeature?.ENABLE_SELF_EDUCATION;
};

/**
 * Gets the environment from CDS env variable.
 * @param {object} cdsData - CDS data
 * @returns {string} help-portal environment ('prod', 'prod_eu' or 'qa')
 */
export const getHelpPortalEnvironment = (cdsData = {}) => {
    const { env } = cdsData;
    let helpPortalEnv;

    switch (env) {
    case 'integration':
        helpPortalEnv = 'qa';
        break;
    case 'eu1':
    case 'eu2':
        helpPortalEnv = 'prod_eu';
        break;
    default:
        helpPortalEnv = 'prod';
        break;
    }

    return helpPortalEnv;
};

// String with comma separated values
// Return [] if role list does not exist in CDS data
export const getRoleList = (cdsData = {}) => (cdsData?.roleList?.split?.(',') || []);

/**
 * Gets role list from CDS data
 * @param {object} cdsData - CDS data
 * @returns {array} role list object
 */
export const getUIPermissions = (cdsData = {}) => {
    const { uiPermissions } = cdsData;
    let uiPermissionsObject;

    // Parse UI Permissions from session
    try {
        uiPermissionsObject = JSON.parse(uiPermissions || '{}');
    } catch (error) {
        uiPermissionsObject = {};
    }

    return uiPermissionsObject;
};

/**
 * Returns whether or not a user is an Outtask company user
 */
export const isOuttaskCompany = (cdsData) => {
    if (!cdsData) {
        return false;
    }
    const uiPermissions = getUIPermissions(cdsData);

    return uiPermissions?.CurrentCompanyId === OUTTASK_COMPANY_ID;
};

/**
 * Returns whether or not a user is an Authorized Support Contact (ASC) admin user
 */
export const isAdminAuthorizedSupportContact = (cdsData) => {
    if (!cdsData) {
        return false;
    }

    const {
        isAdmin,
        isStandard,
        isProfessional,
    } = cdsData || {};

    const roleList = getRoleList(cdsData);
    const isASC = roleList.indexOf('ClientCentralAdmin') !== -1;

    // Standard admins are ASC by default
    return (
        (isStandard && isAdmin)
        || (isProfessional && isASC)
    );
};
