import React, { useContext, useState } from 'react';
import AssistanceStatus from './AssistanceStatus';
import HelpPortalContext from '../HelpPortal/HelpPortalContext';
import SupportModal from '../Support/SupportModal';

const EmailSupportStatus = () => {
    const [showModal, setShowModal] = useState(false);

    const { emailSupportContext } = useContext(HelpPortalContext) || {};
    const { supportType } = emailSupportContext || {};

    return (
        <>
            <AssistanceStatus
                {...emailSupportContext}
                name="Email Support"
                buttonProps={{
                    onClick: () => setShowModal(true),
                }}
            />
            <SupportModal
                onHide={() => setShowModal(false)}
                show={showModal}
                supportType={supportType}
            />
        </>
    );
};

export default EmailSupportStatus;
