import addScriptToDOM from '@concur/core-ui-shell/lib/utils/addScriptToDOM';
import { useEffect, useState } from 'react';

const useExternalAPI = (options) => {
    const {
        apiName,
        isAlreadyLoaded,
        isDisabled,
        shouldStartLoading,
        apiDataAvailable,
        enabledForUser,
        apiAssetUrl,
        apiAssetAttributes,
        onApiSetup,
        onApiScriptLoad,
        isDebugMode,
        asyncConclude,
    } = options || {};

    const [apiScriptLoaded, setApiScriptLoaded] = useState(isAlreadyLoaded);
    const [apiConcluded, setApiConcluded] = useState(isAlreadyLoaded);
    const [apiStatusMessage, setApiStatusMessage] = useState({});

    if (isDisabled) {
        return {
            apiScriptLoaded: false,
            apiConcluded: true,
            apiStatusMessage: 'Feature disabled',
        };
    }

    const concludeApi = (error) => {
        setApiConcluded(true);

        let errorString;
        if (error instanceof Error || typeof error === 'string') {
            errorString = error?.toString?.();
            if (isDebugMode) {
                // eslint-disable-next-line no-console
                console.error(
                    '~~~ HelpPortal Debug Data ~~~\n',
                    '\tuseExternalAPI',
                    '|',
                    apiName,
                    '\n',
                    `\t${errorString}`,
                );
            }
        }

        setApiStatusMessage(errorString);
    };

    const handleApiScriptLoadSuccess = () => {
        setApiScriptLoaded(true);

        try {
            onApiScriptLoad?.(concludeApi);
        } catch (e) {
            concludeApi(e);
        }
        if (!asyncConclude) {
            concludeApi();
        }
    };

    useEffect(() => {
        if (isDebugMode) {
            // eslint-disable-next-line no-console
            console.log(
                '~~~ HelpPortal Debug Data ~~~\n',
                '\tuseExternalAPI',
                '|',
                apiName,
                '\n',
                '\toptions:',
                options,
            );
        }

        if (apiScriptLoaded) {
            concludeApi();
            return;
        }

        if (!shouldStartLoading) {
            return;
        }

        if (!apiDataAvailable) {
            concludeApi('API Data unavailable');
            return;
        }

        if (!enabledForUser) {
            concludeApi('Not enabled for user');
            return;
        }

        onApiSetup?.();

        if (isDebugMode) {
            // eslint-disable-next-line no-console
            console.log(
                '~~~ HelpPortal Debug Data ~~~\n',
                '\tuseExternalAPI',
                '|',
                apiName,
                '\n',
                '\tAdding script to DOM',
            );
        }

        addScriptToDOM(apiAssetUrl, apiAssetAttributes, apiName)
            .then(
                handleApiScriptLoadSuccess,
                () => concludeApi('Could not load script'),
            )
            .catch(
                concludeApi,
            );
    }, [shouldStartLoading, apiDataAvailable, apiAssetUrl]);

    return {
        apiScriptLoaded,
        apiConcluded,
        apiStatusMessage,
    };
};

export default useExternalAPI;
