// Source url, chat url, and org ID are different for each chat environment
export const LIVEAGENT_ENV_CONFIGS = {
    prod: {
        scriptUrl: 'https://c.la4-c1-phx.salesforceliveagent.com/content/g/js/42.0/deployment.js',
        chatUrl: 'https://d.la4-c1-phx.salesforceliveagent.com/chat',
        orgId: '00D600000007Dq3',
    },
    staging: {
        scriptUrl: 'https://c.la4-c1cs-dfw.salesforceliveagent.com/content/g/js/43.0/deployment.js',
        chatUrl: 'https://d.la1-c1cs-ia4.salesforceliveagent.com/chat',
        orgId: '00DTI000000HGDF',
    },
    dev: {
        scriptUrl: 'https://c.la4-c2cs-dfw.salesforceliveagent.com/content/g/js/43.0/deployment.js',
        chatUrl: 'https://d.la4-c2cs-dfw.salesforceliveagent.com/chat',
        orgId: '00D210000009MAb',
    },
};

// Deployment ID is always the same
export const LIVEAGENT_DEPLOYMENT_ID = '57232000000TNvR';

// Chat IDs are different for each chat product
export const LIVEAGENT_CHAT_IDS = {
    professional: '5730e000000blQu',
    standard: '57332000000TO8d',
    standardActivation: '5737V000000PBkV',
    standardTrial: '5730e000000blX2',
};
