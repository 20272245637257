import React, { useContext } from 'react';
import HelpPortalContext from '../HelpPortal/HelpPortalContext';

const EnableNow = () => {
    const { enableNowContext } = useContext(HelpPortalContext) || {};
    const { globals } = enableNowContext || {};

    return (
        <div>
            <pre style={{
                overflow: 'auto',
            }}
            >
                enableNow.globalVariables:
                {
                    `${JSON.stringify(globals, null, 2)}`
                }
            </pre>
        </div>
    );
};

EnableNow.displayName = 'EnableNow';

export default EnableNow;
