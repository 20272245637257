/* eslint-disable import/first */
// Must be the first import
if (process.env.NODE_ENV === 'development') {
    // Must use require here as import statements are only allowed
    // to exist at the top of a file.
    // eslint-disable-next-line global-require
    require('preact/debug');
}
import { exportComponents } from '@concur/react-interop';
import HelpPortal from '../src/HelpPortal/HelpPortal';
import packageInfo from '../package.json';

// Support finding Webpack chunks on CDN
const SCRIPT_IDENTIFIER = 'help-portal';

const scriptElements = document.getElementsByTagName('script');
for (let i = scriptElements.length - 1; i >= 0; i -= 1) {
    const scriptElement = scriptElements[i];
    if (scriptElement.src && scriptElement.src.indexOf(SCRIPT_IDENTIFIER) >= 0) {
        const publicPath = scriptElement.src.substr(0, scriptElement.src.lastIndexOf('/') + 1);
        // eslint-disable-next-line camelcase
        __webpack_public_path__ = publicPath;
        break;
    }
}

const components = {
    ...exportComponents({
        HelpPortal,
    }),
};

// Expose components on the cnqr.coreui namespace
global.cnqr = global.cnqr || {};
global.cnqr.coreui = {
    ...components,
    ...global.cnqr.coreui,
    helpPortalVersion: packageInfo.version,
};
