import lowerCase from '@concur/core-ui-shell/lib/utils/lowerCase';
import {
    COBROWSE_MENUITEM_ID,
    COMMUNITY_MENUITEM_ID,
    CONTACT_SUPPORT_MENUITEM_ID,
    LOCALIZATION_MENUITEM_ID,
    SDG_MENUITEM_ID,
} from '../constants';

// menu item urls that match this pattern are admin help menu items
// and should be excluded from the product help menu items
// eslint-disable-next-line prefer-regex-literals
const ADMIN_ITEMS_REGEX = new RegExp(/.*helpTopic=(.*Admin|ExpenseTools)/g);

const isAdminItem = (item) => item?.url?.match?.(ADMIN_ITEMS_REGEX);

const isServiceDescriptionGuide = (item) => item?.id === SDG_MENUITEM_ID;
const isCommunity = (item) => item?.id === COMMUNITY_MENUITEM_ID;
const isCobrowse = (item) => item?.id === COBROWSE_MENUITEM_ID;
const isLocalization = (item) => item?.id === LOCALIZATION_MENUITEM_ID;
const isContactSupport = (item) => item?.id === CONTACT_SUPPORT_MENUITEM_ID;
const isDivider = (item) => item?.isDivider;

// NOTE: this will go away once the Translation Feedback tool is moved to its own
// top-level menu
export const getOpenTranslationToolItem = (menu) => menu?.subItems?.find?.(isLocalization);

export const getContactSupportItem = (menu) => menu?.subItems?.find?.(isContactSupport);

export const getProductHelpItems = (menu) => menu?.subItems?.filter?.(
    (item) => (
        !isServiceDescriptionGuide(item)
        && !isCommunity(item)
        && !isCobrowse(item)
        && !isContactSupport(item)
        && !isLocalization(item)
        && !isAdminItem(item)
        && !isDivider(item)
    ),
);

export const getAdminHelpItems = (menu) => menu?.subItems?.filter?.(
    (item) => !isServiceDescriptionGuide(item) && isAdminItem(item),
);

export const getDataTestValue = (id, type) => (id
    ? `menu${type?.trim?.() ? `__${type}` : ''}${id?.trim?.() ? `-${lowerCase(id)}` : ''}`
    : null);
