import Text from '@concur/nui-widgets/Text';
import React from 'react';
import PropTypes from 'prop-types';

const FormatJson = ({ children }) => {
    let jsonString = 'Error converting JSON to string';
    try {
        jsonString = JSON.stringify(children, null, 2);
    } catch (e) {
        // sometimes an error is thrown trying to stringify jsonData
    }

    return (
        <Text
            tag="pre"
            style={{
                fontFamily: 'monospace',
                overflow: 'auto',
            }}
        >
            {jsonString}
        </Text>
    );
};

FormatJson.displayName = 'FormatJson';

FormatJson.propTypes = {
    children: PropTypes.string,
};

export default FormatJson;
