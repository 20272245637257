import React from 'react';

const Cobrowsing = () => (
    <pre style={{
        overflow: 'auto',
    }}
    >
        window.GLANCE.VERSION:
        {
            `${JSON.stringify(window?.GLANCE?.VERSION, null, 2)}`
        }
    </pre>
);

Cobrowsing.displayName = 'Cobrowsing';

export default Cobrowsing;
