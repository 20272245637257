import NavMenu from '@concur/nui-widgets/NavMenu';
import Separator from '@concur/nui-widgets/Separator';
import PropTypes from 'prop-types';
import React from 'react';
import HelpMenuItem from './HelpMenuItem';

const HelpMenuGroup = ({
    name,
    items,
    path,
    withDivider,
    styles,
}) => {
    if (!items?.length) return null;

    return (
        <>
            {withDivider && <Separator />}
            <NavMenu.Group header={name}>
                {items?.map?.(
                    (item) => (
                        <HelpMenuItem
                            {...item}
                            styles={styles}
                            key={`${path}-${item?.id || item?.name}`}
                        />
                    ),
                )}
            </NavMenu.Group>
        </>
    );
};

HelpMenuGroup.propTypes = {
    name: PropTypes.string,
    items: PropTypes.array,
    withDivider: PropTypes.bool,
    path: PropTypes.string,
    styles: PropTypes.object,
};
HelpMenuGroup.defaultProps = {
    name: '',
    items: [],
};
HelpMenuGroup.displayName = 'HelpMenuGroup';
export default HelpMenuGroup;
