import withLogger from '@concur/core-ui-shell/lib/hoc/withLogger';
import withStylesLoaded from '@concur/core-ui-shell/lib/hoc/withStylesLoaded';
import compose from '@concur/core-ui-shell/lib/utils/compose';
import useWindowDispatchOnLoad from '@concur/core-ui-shell/lib/utils/useWindowDispatchOnLoad';
import useWindowEventCallback from '@concur/core-ui-shell/lib/utils/useWindowEventCallback';
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import ChatEntryPoint from '../Chat/ChatEntryPoint';
import DebugPanel from '../Debug/DebugPanel';
import HelpMenu from '../HelpMenu/HelpMenu';
import withLoggerData from '../utils/withLoggerData';
import withNuiIntl from '../utils/withNuiIntl';
import withStylesPlaceholder from '../utils/withStylesPlaceholder';
import HelpPortalAPIProvider from './HelpPortalAPIProvider';
import HelpPortalDataProvider from './HelpPortalDataProvider';
import './HelpPortal.css';

const HelpPortal = (props) => {
    const [utilityMenu, setUtilityMenu] = useState();
    const [menuContainer, setMenuContainer] = useState();
    const [menuData, setMenuData] = useState({});
    const helpPortalRef = useWindowDispatchOnLoad('help-portal-loaded');

    const handleContainerLoad = () => {
        window.dispatchEvent(new CustomEvent('help-portal-loaded'));
    };

    useWindowEventCallback('ui-floorplans-loaded', handleContainerLoad);

    const handleContainerAdded = (event) => {
        if (document?.getElementById('help-portal-menu')) {
            const helpPortalMenuEl = document.getElementById('help-portal-menu');
            setUtilityMenu(helpPortalMenuEl?.closest?.('ul'));
            setMenuContainer(helpPortalMenuEl);
            if (event?.detail?.helpMenuItem) {
                setMenuData(event?.detail);
            }
        }
    };
    useWindowEventCallback('help-portal-menu-container-added', handleContainerAdded);

    return (
        <HelpPortalDataProvider {...props}>
            <HelpPortalAPIProvider {...props}>
                <div key="0" ref={helpPortalRef}>
                    {menuContainer && ReactDOM.createPortal(
                        <HelpMenu
                            menuData={menuData}
                            {...props}
                            utilityMenu={utilityMenu}
                        />,
                        menuContainer,
                    )}
                </div>
                <ChatEntryPoint {...props} />
                <DebugPanel menuData={menuData} />
            </HelpPortalAPIProvider>
        </HelpPortalDataProvider>
    );
};

HelpPortal.displayName = 'HelpPortal';

export default compose(
    withStylesPlaceholder,
    withStylesLoaded,
    withNuiIntl,
    withLoggerData,
    withLogger,
)(HelpPortal);
