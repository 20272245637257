const setupJoule = ({
    themeName,
    setJouleWebClient,
}) => {
    if (!window?.sap?.das?.webclient) return;
    setJouleWebClient(window.sap.das.webclient);
    window.sap.das.webclient.setTheme?.(themeName);
};

export default setupJoule;
