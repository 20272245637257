import Checkbox from '@concur/nui-widgets/Checkbox';
import MessageInline from '@concur/nui-widgets/MessageInline';
import React, { useContext } from 'react';
import ApiStatus from './ApiStatus';
import HelpPortalContext from '../HelpPortal/HelpPortalContext';

const ChatStatus = () => {
    const { chatContext } = useContext(HelpPortalContext) || {};
    const {
        toggleChatWindow,
        isChatAgentOnline,
        setIsChatAgentOnline,
        ...statusProps
    } = chatContext || {};

    return (
        <ApiStatus
            {...statusProps}
            apiName="LiveAgent Chat"
            buttonProps={{
                disabled: !isChatAgentOnline,
                onClick: toggleChatWindow,
            }}
            successContent={(
                <MessageInline
                    type="custom"
                    customIconName={isChatAgentOnline
                        ? 'check'
                        : 'no'}
                    typeLabel={isChatAgentOnline
                        ? 'Agent online'
                        : 'Agent offline'}
                    title={isChatAgentOnline
                        ? 'Agent is currently online'
                        : 'Agent is currently offline'}
                    subtitle={(
                        <Checkbox
                            checked={isChatAgentOnline}
                            inline
                            label="Agent online (override)"
                            onChange={setIsChatAgentOnline}
                        />
                    )}
                />
            )}
        />
    );
};

export default ChatStatus;
