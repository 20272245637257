import React, { useState } from 'react';
import classnamesBind from 'classnames/bind';
import PropTypes from 'prop-types';
import { withFormatter } from '@concur/nui-intl-runtime';
import compose from '@concur/core-ui-shell/lib/utils/compose';
import NavMenu from '@concur/nui-widgets/lib/NavMenu/NavMenu';

const CSS_BLOCK = 'sapcnqr-help-menu';

const HelpMenuToggle = ({
    children,
    className: classNameProp,
    formatter,
    styles,
    ...props
}) => {
    const classnames = classnamesBind.bind(styles);
    const [isOpen, setIsOpen] = useState(false);

    return (
        <NavMenu
            iconName="question-mark"
            ariaLabel={formatter.formattedMessage({ id: 'CoreUI.help' })}
            buttonProps={{
                className: classnames(
                    `${CSS_BLOCK}__button`,
                    {
                        [`${CSS_BLOCK}__button--active`]: isOpen,
                    },
                ),
                innerTextClasses: classnames(
                    `${CSS_BLOCK}__button-text`,
                ),
                tooltipProps: {
                    delay: 1000,
                    placement: ['bottom', 'top', 'right', 'left'],
                    outerPopperProps: {
                        showArrow: false,
                        offset: 2,
                    },
                    innerContainerProps: {
                        className: classnames(`${CSS_BLOCK}__tooltip-inner`),
                    },
                },
            }}
            iconProps={{
                ariaHidden: true,
                className: classnames(
                    `${CSS_BLOCK}__icon`,
                ),
                iconName: 'arrow-1-s',
                forceSize: 'xs',
            }}
            onShow={() => setIsOpen(true)}
            onHide={() => setIsOpen(false)}
            overlayProps={{
                popperProps: {
                    'data-help-portal-menu': true,
                },
                popperContentProps: {
                    style: {
                        maxHeight: '70vh', // for 400% zoom compatibility
                    },
                },
                popperPlacement: ['bottom-end'],
            }}
            {...props}
        >
            {children}
        </NavMenu>
    );
};

HelpMenuToggle.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    // set by the withFormatter HOC
    formatter: PropTypes.any,
    styles: PropTypes.object,
};

HelpMenuToggle.defaultProps = {};

HelpMenuToggle.displayName = 'HelpMenuToggle';

export default compose(
    withFormatter,
)(HelpMenuToggle);
