export default (webpackPublicPath) => {
    let cdnUrl = '';
    if (typeof webpackPublicPath !== 'undefined') {
        const temp = webpackPublicPath?.split?.('//');
        const protocol = temp?.[0];
        if (protocol === 'http:' || protocol === 'https:' || protocol === '') {
            const domain = temp?.[1]?.split?.('/')?.[0];
            if (domain?.trim?.()) {
                cdnUrl = `${protocol}//${domain}`;
            }
        } else {
            cdnUrl = webpackPublicPath?.split?.('/')?.[0] || '';
        }
    }

    return cdnUrl;
};
