import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import {
    DEBUG_MODE,
    DEV_MODE_RESPONSE,
    SESSION_DATA_FROM_CACHE,
    SESSION_DATA_FROM_CDS,
    SESSION_DATA_NOT_AVAILABLE,
    SESSION_DATA_NOT_FETCHED,
    SESSION_STORAGE_KEY,
} from '../constants';
import fetchOnScreenHelpData, { fetchOnScreenHelpDevData } from '../utils/fetchOnScreenHelpData';
import { isSelfEducationEnabled } from '../utils/cdsUtils';
import {
    getCallSupportType,
    getEmailSupportType,
    isCallAndEmailSupportEnabled,
} from '../Support/supportUtils';
import HelpPortalContext from './HelpPortalContext';

/*
 * Fetches session when component mounts.
 */
const HelpPortalDataProvider = ({ children, ...props }) => {
    const [cdsData, setCdsData] = useState();
    const [cdsStatus, setCdsStatus] = useState(SESSION_DATA_NOT_FETCHED);

    const {
        cdsURL,
        configOptions,
        logger,
        oshCacheIdentifier,
    } = props || {};

    const {
        userAssistanceSettingsEnabled: isUserAssistanceSettingsEnabled,
        callSupportEnabled,
        emailSupportEnabled,
    } = configOptions || {};

    const query = queryString.parse(window.location.search, { parseBooleans: true });
    const devModeResponse = query?.[DEV_MODE_RESPONSE];
    const isDebugMode = typeof query?.[DEBUG_MODE] === 'boolean' && query?.[DEBUG_MODE];

    const memoizedIsSelfEducationEnabled = useMemo(
        () => isSelfEducationEnabled(cdsData),
        [cdsData],
    );

    const memoizedCallSupportType = useMemo(
        () => getCallSupportType(cdsData),
        [cdsData],
    );

    const memoizedEmailSupportType = useMemo(
        () => getEmailSupportType(cdsData),
        [cdsData],
    );

    const helpPortalContextValue = useMemo(() => ({
        cdsData,
        cdsStatus,
        devModeResponse,
        isDebugMode,
        configOptions,
        isSelfEducationEnabled: memoizedIsSelfEducationEnabled,
        callSupportContext: {
            isDisabled: !callSupportEnabled,
            show: !!(
                callSupportEnabled
                && memoizedIsSelfEducationEnabled
                && memoizedCallSupportType
                && isCallAndEmailSupportEnabled(cdsData)
            ),
            supportType: memoizedCallSupportType,
        },
        emailSupportContext: {
            isDisabled: !emailSupportEnabled,
            show: !!(
                emailSupportEnabled
                && memoizedIsSelfEducationEnabled
                && memoizedEmailSupportType
                && isCallAndEmailSupportEnabled(cdsData)
            ),
            supportType: memoizedEmailSupportType,
        },
    }), [
        cdsData,
        cdsStatus,
        devModeResponse,
        isDebugMode,
        configOptions,
        memoizedIsSelfEducationEnabled,
        callSupportEnabled,
        memoizedCallSupportType,
        emailSupportEnabled,
        memoizedEmailSupportType,
    ]);

    useEffect(() => {
        if (isDebugMode) {
            // eslint-disable-next-line no-console
            console.log(
                '~~~ HelpPortal Debug Data ~~~\n',
                '\tprops:',
                props,
                '\n\tcontext:',
                helpPortalContextValue,
            );
        }
    }, [helpPortalContextValue]);

    const cacheKey = `${SESSION_STORAGE_KEY}-${oshCacheIdentifier}`.toLowerCase?.();

    const foundDataInSessionStorage = () => {
        const existingSessionData = window.sessionStorage
            && JSON.parse(window.sessionStorage.getItem(cacheKey));

        if (oshCacheIdentifier && existingSessionData) {
            setCdsData(existingSessionData);
            setCdsStatus(SESSION_DATA_FROM_CACHE);
            return true;
        }
        return false;
    };

    const readCDSResponse = (response) => {
        setCdsData(response);
        setCdsStatus(SESSION_DATA_FROM_CDS);

        if (oshCacheIdentifier
            && window?.sessionStorage) {
            window.sessionStorage.setItem(
                cacheKey,
                JSON.stringify(response),
            );
        }

        const {
            correlationId,
        } = response?.meta || {};

        logger.info('Successful fetchOnScreenHelpData', {
            correlationId,
            componentName: 'HelpPortal.OnScreenHelp.fetchOnScreenHelpData',
        });
    };

    const handleFetchError = (error) => {
        setCdsStatus(SESSION_DATA_NOT_AVAILABLE);

        logger.error(`Failed fetchOnScreenHelpData: ${error}`, {
            correlationId: error?.correlationId || '',
            componentName: 'HelpPortal.OnScreenHelp.fetchOnScreenHelpData',
        });
    };

    useEffect(() => {
        if (foundDataInSessionStorage()) {
            return;
        }

        setCdsStatus(SESSION_DATA_NOT_FETCHED);

        if (devModeResponse) {
            // dev mode means no CDS call
            // instead, we get a defined response from the CDN
            fetchOnScreenHelpDevData(devModeResponse)
                .then(
                    readCDSResponse,
                    handleFetchError,
                )
                .catch(handleFetchError);

            return;
        }

        const apiContext = {
            url: cdsURL,
        };
        const graphQLQueryArgs = {
            // sessionId will be pulled from CDS context (cookie),
            sessionId: '',
            isUserAssistanceSettingsEnabled,
        };

        fetchOnScreenHelpData(apiContext, graphQLQueryArgs)
            .then(
                readCDSResponse,
                handleFetchError,
            )
            .catch(handleFetchError);
    }, [
        devModeResponse,
        cdsURL,
        isUserAssistanceSettingsEnabled,
    ]);

    return (
        <HelpPortalContext.Provider value={helpPortalContextValue}>
            {children}
        </HelpPortalContext.Provider>
    );
};

HelpPortalDataProvider.propTypes = {
    cdsURL: PropTypes.string,
    children: PropTypes.node,
    configOptions: PropTypes.any,
    logger: PropTypes.any,
    oshCacheIdentifier: PropTypes.string,
};

export default HelpPortalDataProvider;
