import GridList from '@concur/nui-widgets/GridList';
import Button from '@concur/nui-widgets/Button';
import MessageInline from '@concur/nui-widgets/MessageInline';
import React from 'react';

const AssistanceStatus = ({
    name,
    show,
    supportType,
    isDisabled,
    buttonProps,
}) => {
    const renderDetails = () => {
        if (show) {
            return (
                <MessageInline
                    title="Available"
                    subtitle={`Type: ${supportType}`}
                    type="success"
                    typeLabel="Success"
                />
            );
        }

        return (
            <MessageInline
                title="Unavailable"
                subtitle={isDisabled ? 'Feature disabled' : 'Not enabled for user'}
                type="warning"
                typeLabel="Warning"
            />
        );
    };

    return (
        <GridList.Item
            headerToolbarProps={{
                title: name,
            }}
            headerToolbarActions={(
                <Button
                    {...buttonProps}
                    disabled={isDisabled || !show}
                    type="link"
                >
                    Show
                </Button>
            )}
            type={show ? 'success' : 'warning'}
        >
            {renderDetails()}
        </GridList.Item>
    );
};

export default AssistanceStatus;
