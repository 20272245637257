import React, { useContext } from 'react';
import ApiStatus from './ApiStatus';
import HelpPortalContext from '../HelpPortal/HelpPortalContext';

const CobrowsingStatus = () => {
    const { cobrowsingContext } = useContext(HelpPortalContext) || {};

    return (
        <ApiStatus
            {...cobrowsingContext}
            apiName="Glance Cobrowsing"
            buttonProps={{
                onClick: window?.GLANCE?.Cobrowse?.Visitor?.showTerms,
            }}
        />
    );
};

export default CobrowsingStatus;
