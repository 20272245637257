import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

const FirstChildPortal = ({
    containerTag,
    children,
    container,
    ...props
}) => {
    const elementRef = useRef(null);

    useEffect(() => {
        if (container && elementRef.current) {
            container.insertBefore(elementRef.current, container.firstChild);
        }

        return () => {
            if (elementRef.current && elementRef.current.parentNode) {
                elementRef.current.parentNode.removeChild(elementRef.current);
            }
        };
    }, [container]);

    if (!container) return null;

    const ContainerComp = containerTag || 'div';

    return ReactDOM.createPortal(
        <ContainerComp
            {...props}
            ref={elementRef}
        >
            {children}
        </ContainerComp>,
        container,
    );
};

export default FirstChildPortal;
