import React, { useContext } from 'react';
import HelpPortalContext from '../HelpPortal/HelpPortalContext';

const Walkme = () => {
    const { walkmeContext } = useContext(HelpPortalContext) || {};
    const { hidden, globals } = walkmeContext || {};

    return (!hidden
        && (
            <div>
                <pre style={{
                    overflow: 'auto',
                }}
                >
                    walkme.globalVariables:
                    {
                        `${JSON.stringify(globals, null, 2)}`
                    }
                </pre>
            </div>
        )
    );
};

Walkme.displayName = 'Walkme';

export default Walkme;
