import React from 'react';
import GridList from '@concur/nui-widgets/lib/GridList/GridList';
import ChatStatus from './ChatStatus';
import CobrowsingStatus from './CobrowsingStatus';
import EnableNowStatus from './EnableNowStatus';
import WalkmeStatus from './WalkmeStatus';
import CallSupportStatus from './CallSupportStatus';
import EmailSupportStatus from './EmailSupportStatus';

const GetAssistance = () => (
    <GridList
        minItemWidth="30%"
    >
        <WalkmeStatus />
        <EnableNowStatus />
        <ChatStatus />
        <CobrowsingStatus />
        <CallSupportStatus />
        <EmailSupportStatus />
    </GridList>
);

GetAssistance.displayName = 'GetAssistance';

export default GetAssistance;
