import PropTypes from 'prop-types';
import classnamesBind from 'classnames/bind';
import MessageStrip from '@concur/nui-widgets/MessageStrip';
import Section from '@concur/nui-widgets/Section';
import Tab from '@concur/nui-widgets/Tab';
import TabGroup from '@concur/nui-widgets/TabGroup';
import React, { useContext, useState } from 'react';
import Chat from '../Chat/Chat';
import Cobrowsing from '../Cobrowsing/Cobrowsing';
import { SESSION_DATA_FROM_CACHE } from '../constants';
import { parseCESEntitySettings } from '../utils/cdsUtils';
import HelpPortalContext from '../HelpPortal/HelpPortalContext';
import EnableNow from '../EnableNow/EnableNow';
import Walkme from '../WalkMe/Walkme';
import styles from './DebugPanel.css';
import DebugToggle from './DebugToggle';
import GetAssistance from './GetAssistance';
import FormatJson from './FormatJson';
import Joule from '../Joule/Joule';

const CSS_BLOCK = 'sapcnqr-debug-panel';

const DebugPanel = ({ menuData = {} }) => {
    const classnames = classnamesBind.bind(styles);
    const [isExpanded, setIsExpanded] = useState(true);
    const {
        cdsData,
        cdsStatus,
        isDebugMode,
        configOptions,
    } = useContext(HelpPortalContext) || {};

    const handleToggle = () => setIsExpanded(!isExpanded);

    const { cesEntitySettings = '' } = cdsData || {};
    const cesEntitySettingsObj = parseCESEntitySettings(cesEntitySettings);

    return (isDebugMode && (
        <div
            className={classnames(`${CSS_BLOCK}__container`)}
        >
            <DebugToggle
                expanded={isExpanded}
                onClick={handleToggle}
            />
            {
                isExpanded && (
                    <div className={classnames(CSS_BLOCK)} role="dialog">
                        <Section
                            className={classnames(
                                `${CSS_BLOCK}__section`,
                            )}
                            headingLevel={3}
                            title="Help Portal"
                        >
                            {
                                cdsStatus === SESSION_DATA_FROM_CACHE
                                && (
                                    <MessageStrip
                                        typeLabel="warning"
                                        message="Using session data from session storage"
                                        type="warning"
                                    />
                                )
                            }
                            <TabGroup autoWidth selectedTab={0}>
                                <Tab label="Get Assistance">
                                    <Tab.Body>
                                        <GetAssistance />
                                    </Tab.Body>
                                </Tab>
                                <Tab label="Config Options">
                                    <Tab.Body>
                                        <FormatJson>{configOptions}</FormatJson>
                                    </Tab.Body>
                                </Tab>
                                <Tab label="CDS Data">
                                    <Tab.Body>
                                        <FormatJson>{cdsData}</FormatJson>
                                    </Tab.Body>
                                </Tab>
                                <Tab label="Entity Settings">
                                    <Tab.Body>
                                        <FormatJson>{cesEntitySettingsObj}</FormatJson>
                                    </Tab.Body>
                                </Tab>
                                <Tab label="Menu Data">
                                    <Tab.Body>
                                        <FormatJson>{menuData}</FormatJson>
                                    </Tab.Body>
                                </Tab>
                                <Tab label="WalkMe">
                                    <Tab.Body>
                                        <Walkme />
                                    </Tab.Body>
                                </Tab>
                                <Tab label="EnableNow">
                                    <Tab.Body>
                                        <EnableNow />
                                    </Tab.Body>
                                </Tab>
                                <Tab label="LiveAgent Chat">
                                    <Tab.Body>
                                        <Chat data-element="on-screen-help-chat" />
                                    </Tab.Body>
                                </Tab>
                                <Tab label="Cobrowsing">
                                    <Tab.Body>
                                        <Cobrowsing />
                                    </Tab.Body>
                                </Tab>
                                <Tab label="Joule">
                                    <Tab.Body>
                                        <Joule />
                                    </Tab.Body>
                                </Tab>
                            </TabGroup>
                        </Section>
                    </div>
                )
            }
        </div>
    ));
};
DebugPanel.displayName = 'DebugPanel';
DebugPanel.propTypes = {
    menuData: PropTypes.any,
};
export default DebugPanel;
