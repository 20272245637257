/* eslint-disable indent */
import compose from '@concur/core-ui-shell/lib/utils/compose';
import classnamesBind from 'classnames/bind';
import { withFormatter } from '@concur/nui-intl-runtime';
import Button from '@concur/nui-widgets/Button';
import Modal from '@concur/nui-widgets/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import {
    CALL_SUPPORT_FREE_TRIAL,
    CALL_SUPPORT_NO_SERVICE_ASSISTANT,
    CALL_SUPPORT_SERVICE_ASSISTANT_PROFESSIONAL,
    CALL_SUPPORT_SERVICE_ASSISTANT_STANDARD,
    EMAIL_SUPPORT_FREE_TRIAL,
    EMAIL_SUPPORT_SERVICE_ASSISTANT,
    CALL_SUPPORT_TYPES,
    SUPPORT_TYPES,
} from '../constants';
import styles from './SupportModal.css';

const CSS_BLOCK = 'sapcnqr-support-modal';

const EMAIL_ADDRESS_SERVICE_ASSISTANT = 'concur_coachingbench@sap.com';
const EMAIL_ADDRESS_FREE_TRIAL = 'concurfreetrial@sap.com';
const PHONE_NUMBER_FREE_TRIAL = '888-811-2036';
const PHONE_NUMBER_NO_SERVICE_ASSISTANT = '877-901-4960';
const PHONE_NUMBER_SERVICE_ASSISTANT_PROFESSIONAL_US = '833-290-2327';
const PHONE_NUMBER_SERVICE_ASSISTANT_PROFESSIONAL_UK = '800-917-7494';
const PHONE_NUMBER_SERVICE_ASSISTANT_PROFESSIONAL_AU = '180-093-1530';
const PHONE_NUMBER_SERVICE_ASSISTANT_STANDARD_US = '844-849-0886';
const PHONE_NUMBER_SERVICE_ASSISTANT_STANDARD_UK = '800-731-1709';
const PHONE_NUMBER_SERVICE_ASSISTANT_STANDARD_AU = '61-291-137-395';
const GLOBAL_NUMBERS_URL = 'https://help.sap.com/doc/5699b0f3a05644c4b0842322b5d654a3/2022_12/en-US/Contacting_Support.pdf';

const SupportModal = ({
    formatter,
    onHide,
    supportType,
    ...modalProps
}) => {
    const classnames = classnamesBind.bind(styles);
    const isCallSupport = () => CALL_SUPPORT_TYPES.find((type) => type === supportType);

    const renderBody = () => {
        let body = null;

        switch (supportType) {
        case CALL_SUPPORT_FREE_TRIAL:
            body = `
                <p>
                ${formatter.formattedMessage({ id: 'CoreUI.HelpPortal.callSupportInstructionsSingle' })}
                </p>
                <p>
                <strong>${PHONE_NUMBER_FREE_TRIAL}</strong>
                <br />
                ${formatter.formattedMessage({
                    id: 'CoreUI.HelpPortal.countriesDaysTimes',
                    values: {
                        countries: formatter.formattedMessage({ id: 'CoreUI.HelpPortal.usaAndCanada' }),
                        dayStart: formatter.formattedMessage({ id: 'DateTime.Mon' }),
                        dayEnd: formatter.formattedMessage({ id: 'DateTime.Fri' }),
                        timeStart: '8AM',
                        timeEnd: '4PM',
                        timeZone: formatter.formattedMessage({ id: 'DateTime.TimeZoneAbbr50' }),
                    },
                })}
                </p>
            `;
            break;

        case CALL_SUPPORT_NO_SERVICE_ASSISTANT:
            body = `
                <p>
                ${formatter.formattedMessage({ id: 'CoreUI.HelpPortal.callSupportInstructionsSingle' })}
                </p>
                <p>
                <strong>${PHONE_NUMBER_NO_SERVICE_ASSISTANT}</strong>
                <br />
                ${formatter.formattedMessage({
                    id: 'CoreUI.HelpPortal.countriesAvailability',
                    values: {
                        countries: formatter.formattedMessage({ id: 'CoreUI.HelpPortal.usaAndCanada' }),
                        availability: formatter.formattedMessage({ id: 'CoreUI.HelpPortal.available247English' }),
                    },
                })}
                </p>
                <p>
                <a href="${GLOBAL_NUMBERS_URL}" target="_blank">
                ${formatter.formattedMessage({ id: 'CoreUI.HelpPortal.viewGlobalNumbers' })}
                </a>
                </p>
            `;
            break;

        case CALL_SUPPORT_SERVICE_ASSISTANT_PROFESSIONAL:
            body = `
                <p>
                ${formatter.formattedMessage({ id: 'CoreUI.HelpPortal.callSupportInstructionsMultiple' })}
                </p>
                <p>
                <strong>${PHONE_NUMBER_SERVICE_ASSISTANT_PROFESSIONAL_US}</strong>
                <br />
                ${formatter.formattedMessage({
                    id: 'CoreUI.HelpPortal.countriesDaysTimes',
                    values: {
                        countries: formatter.formattedMessage({ id: 'CoreUI.HelpPortal.usaAndCanada' }),
                        dayStart: formatter.formattedMessage({ id: 'DateTime.Mon' }),
                        dayEnd: formatter.formattedMessage({ id: 'DateTime.Fri' }),
                        timeStart: '9AM',
                        timeEnd: '5PM',
                        timeZone: formatter.formattedMessage({ id: 'DateTime.TimeZoneAbbr18' }),
                    },
                })}
                </p>
                <p>
                <strong>${PHONE_NUMBER_SERVICE_ASSISTANT_PROFESSIONAL_UK}</strong>
                <br />
                ${formatter.formattedMessage({
                    id: 'CoreUI.HelpPortal.countriesDaysTimes',
                    values: {
                        countries: formatter.formattedMessage({ id: 'Countries.GB' }),
                        dayStart: formatter.formattedMessage({ id: 'DateTime.Mon' }),
                        dayEnd: formatter.formattedMessage({ id: 'DateTime.Fri' }),
                        timeStart: '9AM',
                        timeEnd: '5PM',
                        timeZone: formatter.formattedMessage({ id: 'DateTime.TimeZoneAbbr30' }),
                    },
                })}
                </p>
                <p>
                <strong>${PHONE_NUMBER_SERVICE_ASSISTANT_PROFESSIONAL_AU}</strong>
                <br />
                ${formatter.formattedMessage({
                    id: 'CoreUI.HelpPortal.countriesDaysTimes',
                    values: {
                        countries: formatter.formattedMessage({ id: 'Countries.AU' }),
                        dayStart: formatter.formattedMessage({ id: 'DateTime.Mon' }),
                        dayEnd: formatter.formattedMessage({ id: 'DateTime.Fri' }),
                        timeStart: '9AM',
                        timeEnd: '5PM',
                        timeZone: formatter.formattedMessage({ id: 'DateTime.TimeZoneAbbr7' }),
                    },
                })}
                </p>
            `;
            break;

        case CALL_SUPPORT_SERVICE_ASSISTANT_STANDARD:
            body = `
                <p>
                ${formatter.formattedMessage({ id: 'CoreUI.HelpPortal.callSupportInstructionsMultiple' })}
                </p>
                <p>
                <strong>${PHONE_NUMBER_SERVICE_ASSISTANT_STANDARD_US}</strong>
                <br />
                ${formatter.formattedMessage({
                    id: 'CoreUI.HelpPortal.countriesDaysTimes',
                    values: {
                        countries: formatter.formattedMessage({ id: 'CoreUI.HelpPortal.usaAndCanada' }),
                        dayStart: formatter.formattedMessage({ id: 'DateTime.Mon' }),
                        dayEnd: formatter.formattedMessage({ id: 'DateTime.Fri' }),
                        timeStart: '9AM',
                        timeEnd: '5PM',
                        timeZone: formatter.formattedMessage({ id: 'DateTime.TimeZoneAbbr18' }),
                    },
                })}
                </p>
                <p>
                <strong>${PHONE_NUMBER_SERVICE_ASSISTANT_STANDARD_UK}</strong>
                <br />
                ${formatter.formattedMessage({
                    id: 'CoreUI.HelpPortal.countriesDaysTimes',
                    values: {
                        countries: formatter.formattedMessage({ id: 'Countries.GB' }),
                        dayStart: formatter.formattedMessage({ id: 'DateTime.Mon' }),
                        dayEnd: formatter.formattedMessage({ id: 'DateTime.Fri' }),
                        timeStart: '9AM',
                        timeEnd: '5PM',
                        timeZone: formatter.formattedMessage({ id: 'DateTime.TimeZoneAbbr30' }),
                    },
                })}
                </p>
                <p>
                <strong>${PHONE_NUMBER_SERVICE_ASSISTANT_STANDARD_AU}</strong>
                <br />
                ${formatter.formattedMessage({
                    id: 'CoreUI.HelpPortal.countriesDaysTimes',
                    values: {
                        countries: formatter.formattedMessage({ id: 'Countries.AU' }),
                        dayStart: formatter.formattedMessage({ id: 'DateTime.Mon' }),
                        dayEnd: formatter.formattedMessage({ id: 'DateTime.Fri' }),
                        timeStart: '9AM',
                        timeEnd: '5PM',
                        timeZone: formatter.formattedMessage({ id: 'DateTime.TimeZoneAbbr7' }),
                    },
                })}
                </p>
            `;
            break;

        case EMAIL_SUPPORT_FREE_TRIAL:
            body = formatter.formattedMessage({
                id: 'CoreUI.HelpPortal.emailForHelp',
                values: {
                    openTag: `<a href="mailto:${EMAIL_ADDRESS_FREE_TRIAL}">`,
                    email: EMAIL_ADDRESS_FREE_TRIAL,
                    closeTag: '</a>',
                },
            });
            break;

        case EMAIL_SUPPORT_SERVICE_ASSISTANT:
            body = formatter.formattedMessage({
                id: 'CoreUI.HelpPortal.contactConcurCoach',
                values: {
                    openTag: `<a href="mailto:${EMAIL_ADDRESS_SERVICE_ASSISTANT}">`,
                    email: EMAIL_ADDRESS_SERVICE_ASSISTANT,
                    closeTag: '</a>',
                },
            });
            break;

        default:
            return null;
        }

        return (
            <div
                className={classnames(CSS_BLOCK)}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: body }}
            />
        );
    };

    return (
        <Modal
            {...modalProps}
            onHide={onHide}
            size="sm"
        >
            <Modal.Header
                closeButton
                closeLabel={formatter.formattedMessage({ id: 'CoreUI.close' })}
            >
                <Modal.Title>
                    {formatter.formattedMessage({
                        id: isCallSupport()
                            ? 'CoreUI.HelpPortal.callSupport'
                            : 'CoreUI.HelpPortal.emailSupport',
                    })}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {renderBody()}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide}>
                    {formatter.formattedMessage({ id: 'Common.done' })}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

SupportModal.propTypes = {
    // set by the withFormatter HOC
    formatter: PropTypes.any,
    show: PropTypes.bool,
    supportType: PropTypes.oneOf(SUPPORT_TYPES),
    onHide: PropTypes.func,
};

SupportModal.displayName = 'SupportModal';

export default compose(
    withFormatter,
)(SupportModal);
